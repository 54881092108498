import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { always, identity, tryCatch } from "ramda";
import { useEffect } from "react";
import { useStaticCallback } from "../../hooks/useStaticCallback.hook";
import { useSnackbar } from "../Modal/ConfirmModal";
import { useAuth } from "./AuthProvider";

const noop = () => {};

// Following code fixes onSuccess and onError removed in react-query 5
// https://tanstack.com/query/v5/docs/react/guides/migrating-to-v5
// https://github.com/TanStack/query/discussions/5279

const performCall = ({
  debug,
  fetchDef: {
    from = identity,
    to = identity,
    url: urlMaybe,
    getUrl: getUrlMaybe,
    ...fetchDef
  } = {}, // TODO: to authFetch?
  performCallFnMaybe,
  authFetch,
}) => {
  return async (...args) => {
    const [rawPayload, ...restArgs] = args;
    const url = logAndThrowTryCatch(
      (payload) => getUrlMaybe?.(payload) || urlMaybe,
      "Error durign getUrlMaybe execution, note - called with raw payload: " +
        getUrlMaybe?.toString(),
      rawPayload // TODO: payload vs rawPayload
    );
    if (debug) {
      console.log(`[performCall] start ${fetchDef.method} ${url}`, {
        rawPayload,
      });
      if (typeof debug === "string") debugger; // "debugger", "break", "d", "b"
    }
    try {
      const payload = logAndThrowTryCatch(
        from,
        `Error during 'from' execution: ${JSON.stringify({ fetchDef: { url, ...fetchDef }, rawPayload })}`, // prettier-ignore
        rawPayload
      );
      const callResult = performCallFnMaybe?.(payload, ...restArgs);
      if (debug) {
        console.log(`[performCall] callResult ${fetchDef.method} ${url}`, {
          rawPayload,
          callResult,
        });
        if (typeof debug === "string") debugger; // "debugger", "break", "d", "b"
      }
      const promise = callResult ?? authFetch({ payload, url, ...fetchDef });
      const rawResponseData = await promise;
      const resData = logAndThrowTryCatch(
        to,
        `Error during 'to' execution: ${JSON.stringify({ fetchDef: { url, ...fetchDef }, rawResponseData })}`, // prettier-ignore
        rawResponseData
      );

      if (debug) {
        console.log(`[performCall] success ${fetchDef.method} ${url}`, {
          payload,
          ...(from !== identity ? { from, rawPayload } : {}),
          resData,
          ...(to !== identity ? { to, rawResponseData } : {}),
        });
        if (typeof debug === "string") debugger; // "debugger", "break", "d", "b"
      }
      return resData;
    } catch (e) {
      if (debug) {
        console.log(`[performCall] error ${fetchDef.method} ${url}`, {
          rawPayload,
          e,
          ...(to === identity ? {} : { to }),
          ...(from === identity ? {} : { from, rawPayload }),
        });
        if (typeof debug === "string") debugger; // "debugger", "break", "d", "b"
      }
      throw e;
    }
  };
};

export const useMyQuery = ({
  fetchDef: { from = always(undefined), to = identity, ...restFD } = {},
  debug,
  onSuccess,
  onError,
  queryFn,
  ...rest
}) => {
  const { authFetch } = useAuth();
  const query = useQuery({
    queryFn: performCall({
      debug,
      fetchDef: { ...restFD, from, to },
      performCallFnMaybe: queryFn,
      authFetch,
    }),
    ...rest,
  });

  // https://github.com/TanStack/query/discussions/5279
  const data = query.data;
  const sCurrent = useStaticCallback(onSuccess ?? noop);
  useEffect(() => {
    if (data) sCurrent(data);
  }, [data, sCurrent]);

  const error = query.error;
  const eCurrent = useStaticCallback(onError ?? noop);
  useEffect(() => {
    if (error) eCurrent(error);
  }, [error, eCurrent]);

  return query;
};

const logAndThrowTryCatch = (fn, msg, rawData) =>
  tryCatch(fn, (e) => {
    console.error(msg, { e, rawData });
    throw e;
  })(rawData);

const mapSnackBarProps = (snackbar = {}, args = [], defaultMessage) => {
  // TODO: tsKey
  const { message, getMessage, ...rest } = snackbar;

  return {
    message: message || getMessage?.(...args) || defaultMessage,
    ...rest,
  };
};

export const useMyMutation = ({
  fetchDef,
  mutationFn: mutationFnProp,
  invalidate = [],
  removeQueries = [],
  prefetchQueries = [],
  refetchQueries = [],
  onSuccess: onSuccessProp,
  onError: onErrorProp,
  debug, // true || "debugger" == "break" == "d" == "b"
  snackbar = { success: false, error: true },
  ...rest
}) => {
  const { authFetch } = useAuth();
  const { show } = useSnackbar();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: performCall({
      debug,
      fetchDef,
      performCallFnMaybe: mutationFnProp,
      authFetch,
    }),
    onSuccess: (...args) => {
      const debugInfo = { args, fetchDef, invalidate, removeQueries, prefetchQueries, refetchQueries, snackbar: snackbar.success, mutationFnProp, rest }; // prettier-ignore
      try {
        if (debug)
          console.log(`%c[useMyMutation.onSuccess]`, "color:green", debugInfo);

        // TODO: consider moving to onSettled, propagate loading state by:
        // make sure to return the Promise from the query invalidation
        // so that the mutation stays in `pending` state until the refetch is finished
        []
          .concat(invalidate)
          .forEach((item) => queryClient.invalidateQueries(item));

        []
          .concat(removeQueries)
          .forEach((item) => queryClient.removeQueries(item));

        []
          .concat(prefetchQueries)
          .forEach((item) => queryClient.prefetchQuery(item));
        []
          .concat(refetchQueries)
          .forEach((item) => queryClient.refetchQueries(item));

        // [].concat(fetchQueries).forEach((item) => queryClient.fetchQuery(item));

        if (snackbar.success) {
          const props = mapSnackBarProps({ ...snackbar.success }, args, "👍");
          show({ type: "success", ...props });
        }
        onSuccessProp?.(...args);
      } catch (error) {
        console.log("[useMyMutation.onSuccess] error", { error, debugInfo });
        debugger;
      }
    },
    onError: (...args) => {
      if (debug) console.log(`[useMyMutation.onError]`, { args, fetchDef, snackbar: snackbar.error, mutationFnProp, rest }); // prettier-ignore

      if (snackbar.error) {
        const props = mapSnackBarProps(
          { ...snackbar.error },
          args,
          args[0]?.message
        );
        show({ type: "error", ...props });
      }
      onErrorProp?.(...args);
    },
    ...rest,
  });

  return mutation;
};
