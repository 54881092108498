import { ErrorBoundary } from "../ErrorBoundary";
import ReactMarkdown from "react-markdown";

export const Markdown = ({ text }) => {
  return (
    <ErrorBoundary
      fallbackRender={({ error }) => {
        console.error("Markdown error", { error, text });
        return text;
      }}
    >
      <ReactMarkdown>{text}</ReactMarkdown>
    </ErrorBoundary>
  );
};
