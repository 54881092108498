import { unparse } from "papaparse";
import { identity } from "ramda";

// https://stackoverflow.com/questions/18249290/generate-csv-for-excel-via-javascript-with-unicode-characters

// https://stackoverflow.com/questions/42462764/javascript-export-csv-encoding-utf-8-issue

var universalBOM = "\uFEFF";
const GET_HREF = {
  blob: ({ string, type }) => {
    const blob = new Blob([string], { type });
    return window.URL.createObjectURL(blob);
  },
  csvWithBOM: ({ string, BOM = universalBOM }) => {
    // Excel forces you to spend some time to figure out how to open a CSV file with UTF-8 encoding or use their format
    const csvWithBOM = BOM + string;
    const blob = new Blob([csvWithBOM], {
      type: "text/csv;charset=utf-8;",
    });
    return window.URL.createObjectURL(blob);
  },
  csvWithBOM2: ({ string, BOM = universalBOM }) => {
    return "data:text/csv; charset=utf-8," + encodeURIComponent(BOM + string);
  },
};

const setExtension = (fileName, ext) => `${fileName}.${ext}`;

export const downloadFile = ({
  string,
  fileName,
  type,
  getHref = GET_HREF.blob,
}) => {
  const el = document.createElement("a");
  el.href = getHref({ string, type });
  el.download = fileName;
  el.style.display = "none";
  document.body.appendChild(el);
  el.click();
  document.body.removeChild(el);
};

export const downloadCsvProps = ({
  data,
  disabled,
  delimiter = ",",
  fileName = "topleader-table",
  getDataset = identity, // https://www.papaparse.com/docs#json-to-csv
}) => {
  return {
    onClick: () =>
      downloadFile({
        // string: unparse({ fields, data }),
        string: unparse(getDataset(data), {
          encoding: "utf-8",
          delimiter,
        }),
        fileName: setExtension(fileName, "csv"),
        type: "text/csv",
        getHref: GET_HREF.csvWithBOM,
      }),
    disabled,
  };
};
