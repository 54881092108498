import { Box, Button, Tooltip } from "@mui/material";
import { intervalToDuration } from "date-fns";
import { isBefore } from "date-fns/fp";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { Icon } from "../../../components/Icon";
import { useMsg } from "../../../components/Msg/Msg";
import { P } from "../../../components/Typography";
import { I18nContext } from "../../I18n/I18nProvider";
import { messages } from "../messages";
import { AddRecipient } from "./AddRecipient";

const ConditionalWrapper = ({
  condition,
  WrapperComponent,
  wrapperProps = {},
  children,
}) => {
  return condition ? (
    <WrapperComponent {...wrapperProps}>{children}</WrapperComponent>
  ) : (
    children
  );
};

function useInterval(callback, delay) {
  const savedCallback = useRef();
  savedCallback.current = callback;

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const SharedWith = ({ feedback }) => {
  const msg = useMsg({ dict: messages });
  const [addVisible, setAddVisible] = useState(false);
  const handleAdd = () => setAddVisible(true);

  const [, rerender] = useReducer((x) => x + 1, 0);
  useInterval(rerender, 60000);
  const { i18n } = useContext(I18nContext);
  const isStillValid = isBefore(feedback.validTo, Date.now());
  const timeToExpire = i18n.dffp.formatDurationWithMergeOptions(
    {},
    intervalToDuration({
      start: Date.now(),
      end: feedback.validTo,
    })
  );
  // const timeToExpire = i18n.dffp.formatDistanceWithMergeOptions(
  //   { addSuffix: false, includeSeconds: true },
  //   i18n.parseUTCLocal(feedback.validTo),
  //   Date.now()
  // );

  console.log("[SharedWith.rndr]", { feedback, isStillValid, timeToExpire });

  // if (!feedback?.recipients) return null;

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <P bigger sx={{ color: "black" }}>
          {msg("feedback.results.shared-with")}
        </P>
        <ConditionalWrapper
          condition={isStillValid}
          WrapperComponent={Tooltip}
          wrapperProps={{
            title: timeToExpire,
          }}
        >
          <Button
            variant="text"
            startIcon={<Icon name="Add" />}
            onClick={handleAdd}
            sx={{
              ml: 1,
              visibility: addVisible ? "hidden" : "visible",
            }}
          >
            {msg("feedback.results.add-email")}
          </Button>
        </ConditionalWrapper>
      </Box>

      {addVisible && (
        <AddRecipient
          feedback={feedback}
          onSuccess={() => setAddVisible(false)}
        />
      )}

      {feedback?.recipients?.map(({ username, submitted }, i) => (
        <P key={username} bigger sx={{ mt: 2 }}>
          {username}
        </P>
      ))}
    </Box>
  );
};
