import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createContext, useCallback, useContext, useRef } from "react";
import { useIntl } from "react-intl";
import { useSessionStorage } from "../../hooks/useLocalStorage";
import { useStaticCallback } from "../../hooks/useStaticCallback.hook";
import { throwResponse } from "./fetchError.utils";
import { getInit, parseResponse, qstr } from "./utils";

// TODO: extract to separate feature
export * from "./reactQueryWrappers";

export const AuthContext = createContext(null);

const _login = ({ authFetch, username, password }) =>
  authFetch({
    method: "POST",
    url: "/login",
    data: (() => {
      const formData = new FormData();
      formData.append("username", username);
      formData.append("password", password);
      // console.log("_login", { formData, username, password });
      return formData;
    })(),
  });

const _resetPass = ({ authFetch, password, token }) =>
  authFetch({
    method: "POST",
    url: `/api/public/set-password/${token}`,
    data: { password },
  });

const _fetchUser = ({ authFetch }) =>
  authFetch({ url: "/api/latest/user-info" });

// src/main/java/com/topleader/topleader/user/User.java
export const Authority = {
  COACH: "COACH",
  USER: "USER",
  ADMIN: "ADMIN",
  HR: "HR",
  MANAGER: "MANAGER",
};

export function AuthProvider({ children }) {
  // Should reflect JSESSIONID cookie obtained during login (httpOnly, not accessible by JS):
  const [isLoggedIn, setIsLoggedIn] = useSessionStorage("isLoggedIn", false);
  const queryClient = useQueryClient();
  const intl = useIntl();

  const signout = useStaticCallback(() => {
    queryClient.removeQueries();
    queryClient.clear();
    setIsLoggedIn(false);
  });

  const intlRef = useRef(intl);
  intlRef.current = intl;
  const authFetch = useCallback(
    ({
      url,
      query,
      method = "GET",
      payload,
      data = payload, // TODO: data is too ambiguous, rename to payload
      isPublicApi = url?.includes("/api/public/"),
    }) =>
      fetch(qstr(url, query), getInit({ method, data }))
        .then(async (response) => {
          const { jsonMaybe, textMaybe, error } = await parseResponse(response);

          if (error) {
            // prettier-ignore
            console.error(`[authFetch] not parsed: ${method} ${url}`, { response, error });
            debugger;
            return { response };
          }

          if (!response.ok) {
            throwResponse({
              response,
              jsonMaybe,
              textMaybe,
              intl: intlRef.current,
            });
          }
          if (jsonMaybe) return jsonMaybe;
          if (textMaybe) return textMaybe;

          return { response, isUnparsedResponse: true };
        })
        .catch((e) => {
          if (e?.response?.status === 401 && !isPublicApi) {
            console.error("[authFetch] unauthorized", { e, url, method });
            signout();
          }
          throw e;
        }),
    [signout]
  );

  const userQuery = useQuery({
    queryKey: ["user-info"],
    queryFn: () => _fetchUser({ authFetch }),
    enabled: !!isLoggedIn,
    retry: Infinity,
    refetchOnWindowFocus: false,
  });

  const loginMutation = useMutation({
    mutationFn: (fields) => _login({ authFetch, ...fields }),
    onSuccess: () => setIsLoggedIn(true),
  });
  const resetPasswordMutation = useMutation({
    mutationFn: (fields) => _resetPass({ authFetch, ...fields }),
    onSuccess: () => setIsLoggedIn(true),
  });
  const hasAuthority = useCallback(
    (authority) => userQuery.data?.userRoles?.includes(authority),
    [userQuery.data?.userRoles]
  );

  const value = {
    isLoggedIn,
    loginMutation,
    resetPasswordMutation,
    userQuery,
    user: userQuery, // TODO: replace by userQuery
    signout,
    authFetch,
    fetchUser: () => {
      queryClient.invalidateQueries({ queryKey: ["user-info"] });
    },
    hasAuthority,
    isUser: hasAuthority(Authority.USER),
    isCoach: hasAuthority(Authority.COACH),
    isHR: hasAuthority(Authority.HR),
    isAdmin: hasAuthority(Authority.ADMIN),
    isManager: hasAuthority(Authority.MANAGER),
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}
