import { Box, Card, CardHeader, Divider, Stack } from "@mui/material";
import { SlotChip } from "../../features/Team/Team.page";
import { DownloadData } from "../DownloadData/DownloadData";
import { H2 } from "../Typography";
import { TLLoadableTable } from "./TLLoadableTable";

export const TLTableWithHeader = ({
  titleDef,
  title = titleDef ? (
    <Box sx={{ display: "inline-flex", alignItems: "baseline" }}>
      <H2>{titleDef?.heading}</H2>
      {!!titleDef?.chipVisible && (
        <SlotChip sx={{ display: "inline-flex", p: 0.75, ml: 4 }}>
          {titleDef?.chip}
        </SlotChip>
      )}
    </Box>
  ) : undefined,
  subheader,
  action,
  columns,
  query,
  expandedRowRender,
  bodyBefore,
  headerBefore,
  exportData,
  headerVisible = title || subheader || action || !!exportData,
  sx = {},
}) => {
  return (
    <Card sx={sx}>
      {headerVisible && (
        <CardHeader
          disableTypography
          title={title}
          subheader={subheader}
          action={
            <Stack direction="row" spacing={2}>
              {query && !query.error && exportData && (
                <DownloadData query={query} fileName={exportData.fileName} />
              )}
              {action}
            </Stack>
          }
        />
      )}
      <Divider />
      <TLLoadableTable
        columns={columns}
        query={query}
        expandedRowRender={expandedRowRender}
        bodyBefore={bodyBefore}
        headerBefore={headerBefore}
      />
    </Card>
  );
};
