import { isFuture, isToday } from "date-fns";
import { isValid } from "date-fns/fp";

export const notBlank =
  ({ gtLen = 0 } = {}) =>
  (v) =>
    v?.trim?.()?.length > gtLen;
export const minLength =
  ({ gteLength = 1 } = {}) =>
  (v) =>
    v?.length >= gteLength;
export const maxLength =
  ({ lteLength = 1000 } = {}) =>
  (v) =>
    v?.length <= lteLength;
export const rePattern =
  ({ regexpToMatch } = {}) =>
  (v) => {
    if (!v?.length) return true;
    return !!v.match(regexpToMatch);
  };
export const email = () => (v) => {
  return rePattern({
    regexpToMatch: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  })(v);
};

export const validDate = () => (v) => {
  if (!v) return true; // optional date fields
  return isValid(v);
};

// TODO: cached fn, not reacting to forbiddenList changes
export const forbiddenValues =
  ({ forbiddenList = [] } = {}) =>
  (v) => {
    if (Array.isArray(v)) {
      debugger;
    } else {
      console.log("%c[forbiddenValues]", "color:lime", { forbiddenList, v });
      return !forbiddenList.includes(v);
    }
  };

// old validations for direct use in rules prop:
export const invalidDate = (v) => {
  if (!v) return true; // optional date fields
  return isValid(v);
};

export const invalidTime = (v) => {
  if (!v) return true; // optional date fields
  return isValid(v);
};

export const todayOrFuture = (v) => {
  if (!v) return true; // optional date fields
  return isToday(v) || isFuture(v);
};
