import { Box } from "@mui/material";
import { getValidateParamsMaybe } from "./parametrizedValidate";

export const TextFieldHelperText = ({
  withHelperTextSpace,
  charCounter,
  fieldErrorElement,
}) => {
  return (
    <Box
      component={!withHelperTextSpace && !charCounter ? "span" : undefined}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      {fieldErrorElement}
      {withHelperTextSpace ? <>&nbsp;</> : null}
      {charCounter && (
        <Box component="span" sx={{ textAlign: "right", flexGrow: 1 }}>
          {`${[charCounter.value || 0, charCounter.max]
            .filter((v) => typeof v === "number")
            .join("/")}`}
        </Box>
      )}
    </Box>
  );
};
