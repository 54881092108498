import React from "react";
import { useAuth } from "../Authorization";
import { Loaders } from "../QM/QueryRenderer";

export const GlobalLoader = ({ children }) => {
  const auth = useAuth();

  if (!auth) {
    console.log("GlobalLoader - missing auth");
    debugger;
  }

  if (auth?.isLoggedIn && !auth?.user.data)
    return <Loaders.Backdrop gray opaque />;
  else return children;
};
