import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { evolve } from "ramda";
import { useForm } from "react-hook-form";
import { RHFTextField } from "../../../components/Forms";
import { RHForm } from "../../../components/Forms/Form";
import { useMsg } from "../../../components/Msg/Msg";
import { useUpdateFeedbackFormMutation } from "../api";
import { messages } from "../messages";

export const AddRecipient = ({ feedback, onSuccess }) => {
  const msg = useMsg({ dict: messages });
  const form = useForm({
    defaultValues: { recipient: "" },
  });
  const mutation = useUpdateFeedbackFormMutation({
    params: { id: feedback?.id },
    enabled: !!feedback?.id,
    onSuccess: () => {
      form.reset();
      onSuccess?.();
    },
  });
  const handleSubmit = ({ recipient }) => {
    const updated = evolve({
      recipients: (prev) => [...prev, { username: recipient }],
    })(feedback);
    mutation.mutate(updated);
  };
  const loading = mutation.isPending;

  return (
    <RHForm form={form} onSubmit={handleSubmit}>
      <Box
        sx={{
          display: "flex",
          alignItems: "baseline",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: 2,
          mt: 1,
        }}
      >
        <RHFTextField
          name={"recipient"}
          placeholder={msg("feedback.results.email.placeholder")}
          parametrizedValidate={[["required"], ["email"]]}
          size="small"
          sx={{ flexGrow: 2 }}
          autoFocus
        />
        <LoadingButton variant="contained" type="submit" loading={loading}>
          {msg("feedback.results.add")}
        </LoadingButton>
      </Box>
    </RHForm>
  );
};
