import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { useCallback } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { TLDialog } from "../../components/Dialog";
import {
  DatePickerField,
  HiddenField,
  RHFTextField,
} from "../../components/Forms";
import { invalidDate, todayOrFuture } from "../../components/Forms/validations";
import { Icon } from "../../components/Icon";
import { useMsg } from "../../components/Msg/Msg";
import { P } from "../../components/Typography";
import { messages } from "./messages";
import { generalMessages } from "../../components/messages";
import { LoadingButton } from "@mui/lab";

export const SHARE_FIELDS = {
  validTo: "validTo",
  emailList: "emailList",
};

const FIELD_FIELDS = {
  email: "email",
  // role: "role",
};

export const FIELD_DEFAULT_VALUES = {
  email: "",
  // role: null,
};

// const ROLE_OPTIONS = [{ value: "manager", label: "Manager" }];

const ShareLink = ({ link }) => {
  const onCopy = useCallback(() => {
    navigator.clipboard.writeText(link).then(
      () => console.log("Copy success", { link }),
      () => console.log("Copy error", { link })
    );
  }, [link]);

  return (
    <OutlinedInput
      name="link"
      value={link}
      label=""
      disabled
      size="small"
      placeholder="Not available yet"
      sx={{ flexGrow: 2 }}
      endAdornment={
        <InputAdornment position="end">
          {/* <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end" > {showPassword ? <VisibilityOff /> : <Visibility />} </IconButton> */}
          <IconButton onClick={onCopy} disabled={!link} sx={{ mr: -1 }}>
            <Icon name="ContentCopy" />
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

const EmailListItem = ({ index, remove, canRemove = !!remove, getName }) => {
  const msg = useMsg({ dict: messages });
  return (
    <Box display="flex" alignItems="flex-start" gap={3}>
      <HiddenField name={getName("id")} />
      <HiddenField name={getName("submitted")} />
      <RHFTextField
        name={getName(FIELD_FIELDS.email)}
        placeholder={msg("feedback.create.share-modal.email.placeholder")}
        rules={{ required: "Required" }}
        size="small"
        sx={{ flexGrow: 2 }}
      />

      {canRemove && (
        <IconButton
          onClick={() => remove(index)}
          sx={{
            width: "40px",
            visibility: canRemove ? "visible" : "hidden",
            color: "error.main",
          }}
        >
          <Icon name="DeleteOutlined" />
        </IconButton>
      )}
    </Box>
  );
};

const EmailList = ({ name, addLabel }) => {
  const { fields, append, remove } = useFieldArray({
    name,
    rules: { required: true },
  });
  console.log({ fields });

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      {fields.map((field, i) => (
        <EmailListItem
          key={field.id}
          index={i}
          remove={remove}
          canRemove={fields.length > 1}
          // canRemove={i > 0}
          getName={(fieldName) => `${name}.${i}.${fieldName}`}
          sx={{ mt: 3 }}
        />
      ))}
      <Button
        onClick={() => append(FIELD_DEFAULT_VALUES)}
        startIcon={<Icon name={"Add"} />}
        sx={{ alignSelf: "flex-start" }}
      >
        {addLabel}
      </Button>
    </Box>
  );
};

// TODO: Form reset?
export const ShareFeedbackModal = ({
  open,
  onSubmit,
  onSaveDraft,
  onClose,
  link,
  isPending,
  isSubmitting,
  isSavingDraft,
  initialValues,
}) => {
  const msg = useMsg({ dict: messages });
  const generalMsg = useMsg({ dict: generalMessages });
  const form = useForm({
    mode: "onSubmit",
    defaultValues: initialValues || {
      [SHARE_FIELDS.validTo]: null,
      [SHARE_FIELDS.emailList]: [FIELD_DEFAULT_VALUES],
    },
  });
  const handleClose = useCallback(() => {
    // TODO: reset form?
    onClose();
  }, [onClose]);

  return (
    <FormProvider {...form}>
      <TLDialog
        iconName={"PersonAdd"}
        title={msg("feedback.create.share-modal.title")}
        desc={msg("feedback.create.share-modal.desc")}
        open={open}
        onClose={handleClose}
      >
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
            gap: 3,
          }}
        >
          {null && <ShareLink link={link} />}
          <P sx={{ color: "black" }}>
            {msg("feedback.create.share-modal.deadline")}
          </P>
          <DatePickerField
            name={SHARE_FIELDS.validTo}
            rules={{
              required: "Required",
              validate: { invalidDate, todayOrFuture },
            }}
            disablePast
            clearable
          />
        </DialogContent>
        <DialogContent dividers>
          <EmailList
            name={SHARE_FIELDS.emailList}
            addLabel={msg("feedback.create.share-modal.add-email")}
          />
        </DialogContent>
        <DialogActions sx={{ gap: 2 }}>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => handleClose()}
            disabled={isPending}
          >
            {msg("feedback.create.share-modal.cancel")}
          </Button>
          <LoadingButton
            fullWidth
            variant="text"
            type="submit"
            onClick={form.handleSubmit(onSaveDraft)}
            disabled={isPending}
            loading={isSavingDraft}
            // loading
            loadingPosition="end"
          >
            {generalMsg("general.save-draft")}
          </LoadingButton>
          <LoadingButton
            fullWidth
            variant="contained"
            type="submit"
            onClick={form.handleSubmit(onSubmit)}
            disabled={isPending}
            loading={isSubmitting}
            loadingPosition="end"
          >
            {msg("feedback.create.share-modal.share")}
          </LoadingButton>
        </DialogActions>
      </TLDialog>
    </FormProvider>
  );
};
