import { useEffect } from "react";
import { useAuth } from "./AuthProvider";
import { Navigate, useLocation } from "react-router-dom";
import { routes } from "../../routes";

// same logic as in RequireAuth, but we save previous location passed in state
export const SignOutPage = (props) => {
  const { isLoggedIn, signout } = useAuth();
  const location = useLocation();

  console.log("[SignOutPage.rndr]", { location, props });
  if (!location.state?.from) {
    console.error(
      "[SignOutPage.rndr] pass location.state.from when navigating to SignOut!",
      { location }
    );
  }

  useEffect(() => {
    signout();
    // console.log("[SignOutPage.eff]", { "location.state": location.state });
    // if (location.state) {
    //   // navigate with state does not work!
    //   navigate(routes.signIn, { state: location.state?.from, replace: true });
    // }
  }, [signout]);

  if (isLoggedIn) return null;
  else
    return (
      <Navigate
        to={routes.signIn}
        state={{ from: location.state?.from }}
        replace
      />
    );
};
