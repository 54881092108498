import { prop } from "ramda";
import { defineMessages } from "react-intl";
import { isProduction } from "../../utils/environment";

const ERROR_CODES = {
  FIELD_OUTSIDE_OF_FRAME: "field.outside.of.frame",
  MORE_THEN_24_EVENT: "event.longer.that.24",

  SESSION_IN_PAST: "session.in.past",
  TIME_NOT_AVAILABLE: "time.not.available",

  NOT_ENOUGH_CREDITS: "not.enough.credits",

  EMAIL_USED: "email.used",
  NOT_PART_OF_COMPANY: "not.part.of.company",

  INVALID_PASSWORD: "invalid.password",

  ALREADY_EXISTING: "already.existing",

  ALREADY_SUBMITTED: "form.already.submitted",
};

const getTsKey = (code = "", prop = "message") => `dict.error.${code}.${prop}`;

const errMessages = defineMessages({
  [getTsKey(ERROR_CODES.FIELD_OUTSIDE_OF_FRAME)]: {
    // id: getTsKey(ERROR_CODES.FIELD_OUTSIDE_OF_FRAME), // not working, extraction of messages is static analysis
    id: "dict.error.field.outside.of.frame.message",
    defaultMessage: "{apiMessage}",
  },
  [getTsKey(ERROR_CODES.MORE_THEN_24_EVENT)]: {
    // id: getTsKey(ERROR_CODES.MORE_THEN_24_EVENT),
    id: "dict.error.event.longer.that.24.message",
    defaultMessage: "{apiMessage}",
  },
  [getTsKey(ERROR_CODES.SESSION_IN_PAST)]: {
    // id: getTsKey(ERROR_CODES.SESSION_IN_PAST),
    id: "dict.error.session.in.past.message",
    defaultMessage: "{apiMessage}",
  },
  [getTsKey(ERROR_CODES.TIME_NOT_AVAILABLE)]: {
    // id: getTsKey(ERROR_CODES.TIME_NOT_AVAILABLE),
    id: "dict.error.time.not.available.message",
    defaultMessage: "{apiMessage}",
  },
  [getTsKey(ERROR_CODES.NOT_ENOUGH_CREDITS)]: {
    // id: getTsKey(ERROR_CODES.NOT_ENOUGH_CREDITS),
    id: "dict.error.not.enough.credits.message",
    defaultMessage: "{apiMessage}",
  },
  [getTsKey(ERROR_CODES.EMAIL_USED)]: {
    // id: getTsKey(ERROR_CODES.EMAIL_USED),
    id: "dict.error.email.used.message",
    defaultMessage: "{apiMessage}",
  },
  [getTsKey(ERROR_CODES.NOT_PART_OF_COMPANY)]: {
    // id: getTsKey(ERROR_CODES.NOT_PART_OF_COMPANY),
    id: "dict.error.not.part.of.company.message",
    defaultMessage: "{apiMessage}",
  },
  [getTsKey(ERROR_CODES.INVALID_PASSWORD)]: {
    // id: getTsKey(ERROR_CODES.INVALID_PASSWORD),
    id: "dict.error.invalid.password.message",
    defaultMessage: "{apiMessage}",
  },
  [getTsKey(ERROR_CODES.ALREADY_EXISTING)]: {
    // id: getTsKey(ERROR_CODES.ALREADY_EXISTING),
    id: "dict.error.already.existing.message",
    defaultMessage: "{apiMessage}",
  },
  [getTsKey(ERROR_CODES.ALREADY_SUBMITTED)]: {
    id: "dict.error.form.already.submitted.message",
    defaultMessage: "The form has already been submitted",
  },
});

const aErr = (jsonMaybe) => [].concat(jsonMaybe || []).filter(Boolean);

// https://github.com/top-leader-cz/top-leader-be/blob/main/src/main/java/com/topleader/topleader/exception/ErrorCodeConstants.java
const exampleJsonMaybe = [
  {
    errorCode: "not.enough.credits",
    fields: [{ name: "user", value: "no-credit-user" }],
    errorMessage: "User does not have enough credit",
  },
];
const feedbackExample = [
  {
    errorCode: "NotNull", // TODO: BE, not listed
    fields: [
      {
        name: "validTo",
        value: "null",
      },
    ],
    errorMessage: "must not be null",
  },
];

const extractApiMsg = (error) => {
  const fieldsMsgMaybe = error?.fields
    ?.map(({ name, value }) => name)
    ?.join(", ");
  const apiMessage = [
    error?.errorMessage,
    fieldsMsgMaybe,
    error?.error,
    error?.message,
  ]
    .filter(Boolean)
    .join(" - ");
  return apiMessage;
};

const translateErr = ({ intl, error }) => {
  const apiMessage = extractApiMsg(error);
  try {
    if (!error?.errorCode)
      return { translated: "", info: "No errorCode", apiMessage };

    const tsKey = getTsKey(error?.errorCode);
    const msgObj = errMessages[tsKey];
    if (!msgObj?.id) {
      return {
        translated: "",
        info: !msgObj
          ? "Missing translation for key: " + tsKey
          : "No id in translation object for key: " + tsKey,
        apiMessage,
        tsKey,
      };
    }
    return {
      translated: intl.formatMessage(msgObj, { apiMessage }),
      apiMessage,
      tsKey,
      info: "",
    };
  } catch (e) {
    debugger;
    console.log("[translateErr er]", { e, error });
    return { translated: "", info: "Error during translation", apiMessage };
  }
};

const DEFAULT_ERROR_MESSAGE = "Oops!";

const stringifySensitive = (data, defaultMsg = DEFAULT_ERROR_MESSAGE) => {
  return isProduction() ? defaultMsg : JSON.stringify(data);
};

const translateErrors = ({ response, jsonMaybe, textMaybe, intl }) => {
  const errArr = aErr(jsonMaybe); // just for sure, TODO: move to authFetch?
  if (!errArr.length) {
    return stringifySensitive({
      status: response?.status,
      textMaybe,
      jsonMaybe,
    });
  }
  const translatedArr = errArr.map((error) => {
    const { translated, info, apiMessage } = translateErr({ intl, error });

    return { error, info, translated, apiMessage };
  });

  if (translatedArr.filter(prop("translated")).length !== errArr.length) {
    // prettier-ignore
    console.log("TODO: untranslated error", { errArr, translatedArr, jsonMaybe, response, });
    // debugger;
  }

  return translatedArr
    .map(({ translated, apiMessage, error, info }) => {
      if (translated) return translated;
      if (apiMessage) {
        if (info && !isProduction())
          return `${apiMessage} [DEV info - using API message: ${info}]`;

        return apiMessage;
      }
      return stringifySensitive({ error, info });
    })
    .join(". ");
};

export const throwResponse = ({ response, jsonMaybe, textMaybe, intl }) => {
  const message = translateErrors({
    response,
    jsonMaybe,
    textMaybe,
    intl,
  });
  const error = new Error(message);
  error.response = response;
  error.jsonMaybe = jsonMaybe;
  error.textMaybe = textMaybe;
  // debugger;
  throw error;
};
