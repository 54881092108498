import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
} from "@mui/material";
import { Icon } from "../../../components/Icon";
import { useMsg } from "../../../components/Msg/Msg";
import { HeadingWithIcon } from "../../Dashboard/HeadingWithIcon";
import { messages } from "../messages";
import { InfoBox } from "./InfoBox";

export const FeedbackResultsSummaryCard = ({ summary, sx }) => {
  const msg = useMsg({ dict: messages });
  const { strongAreas, areasOfImprovement } = summary || {};

  if (!strongAreas && !areasOfImprovement) return null;

  return (
    <Card sx={sx}>
      <Accordion
        defaultExpanded
        sx={{
          // bgcolor: gray50,
          borderRadius: "8px",
          "&:before": {
            display: "none", // remove border
          },
        }}
      >
        <AccordionSummary
          expandIcon={<Icon name="ExpandMore" />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <HeadingWithIcon emphasized title={msg("feedback.results.summary")} />
        </AccordionSummary>
        {/* <CardActionArea sx={{}} href={href}> */}
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "stretch",
            gap: 3,
            pt: 0,
          }}
        >
          {!!strongAreas && (
            <InfoBox
              sx={{ flex: 1 }}
              title={msg("feedback.results.summary.strong-areas")}
              text={strongAreas}
            />
          )}
          {!!areasOfImprovement && (
            <InfoBox
              sx={{ flex: 1 }}
              title={msg("feedback.results.summary.areas-of-improvement")}
              text={areasOfImprovement}
            />
          )}
        </AccordionDetails>
        {/* </CardActionArea> */}
      </Accordion>
    </Card>
  );
};
