import { useState } from "react";
import { Icon } from "../Icon";
import { Button, Menu, MenuItem } from "@mui/material";
import { useMsg } from "../Msg/Msg";
import { generalMessages } from "../messages";
import { downloadCsvProps } from "./downloadCsvProps";

const ButtonMenu = ({ button = { children: "Click me" }, items = [] }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        {...button}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {items.map((item) => (
          <MenuItem
            {...item}
            onClick={() => {
              item.onClick?.();
              handleClose();
            }}
          />
        ))}
      </Menu>
    </div>
  );
};

export const DownloadData = ({
  query,
  data = query?.data,
  disabled = query?.isFetching,
  fileName,
}) => {
  const msg = useMsg({ dict: generalMessages });
  // const { guessedCsvDelimiter } = useContext(I18nContext);

  return (
    <ButtonMenu
      button={{
        variant: "outlined",
        "aria-label": "download CSV",
        startIcon: <Icon name="Download" />,
        children: msg("general.export.csv"),
      }}
      items={[
        {
          key: 1,
          children: msg("general.delimiter", { delimiter: '","' }),
          ...downloadCsvProps({
            data,
            disabled,
            delimiter: ",",
            fileName,
          }),
        },
        {
          key: 2,
          children: msg("general.delimiter", { delimiter: '";"' }),
          ...downloadCsvProps({
            data,
            disabled,
            delimiter: ";",
            fileName,
          }),
        },
      ]}
    />
  );
};
