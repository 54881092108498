import { LoadingButton } from "@mui/lab";
import { Card, CardContent, CircularProgress, TextField } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { pick } from "ramda";
import { useEffect, useRef, useState } from "react";
import { defineMessages } from "react-intl";
import { TextFieldHelperText } from "../../components/Forms/TextFieldHelperText";
import { validationMessages } from "../../components/Forms/validationMessages";
import { Icon } from "../../components/Icon";
import { Layout } from "../../components/Layout";
import { generalMessages } from "../../components/messages";
import { useMsg } from "../../components/Msg/Msg";
import { H2 } from "../../components/Typography";
import { useMyMutation, useMyQuery } from "../Authorization/AuthProvider";
import { useBlockNavigationPopup } from "../Feedback/CreateFeedbackForm";

const messages = defineMessages({
  "notes.title": {
    id: "notes.title",
    defaultMessage: "Notes",
  },
});

const useNoteQuery = () =>
  useMyQuery({
    queryKey: ["user-info", "notes"],
    fetchDef: {
      url: "/api/latest/user-info/notes",
    },
  });

const useNoteMutation = () => {
  const queryClient = useQueryClient();
  return useMyMutation({
    fetchDef: {
      method: "POST",
      url: `/api/latest/user-info/notes`,
      from: pick(["notes"]),
    },
    invalidate: { queryKey: ["user-info", "notes"] },
    snackbar: { success: false, error: true },
    onSettled: (data, error, variables, context) => {
      console.log("useNoteMutation", { data, error, variables, context });
      if (data)
        queryClient.setQueryData(["user-info", "notes"], (old) => {
          console.log({ variables, old, data });
          return { ...data };
        });
    },
  });
};

export const NotesPage = () => {
  const msg = useMsg({ dict: messages });
  const generalMsg = useMsg({ dict: generalMessages });
  const validationMsg = useMsg({ dict: validationMessages });
  const query = useNoteQuery();
  const initializedRef = useRef(false);
  const [note, setNote] = useState("");
  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    // isFetching needed - old query data returned from cache, not updated by setQueryData. Save, before query fetch leave page and return
    if (!query.data || query.isFetching || initializedRef.current)
      // if (!query.data || initializedRef.current)
      return console.log("Not inited:", {
        "query.data": query.data,
        "initializedRef.current": initializedRef.current,
      });
    const initNote =
      typeof query.data.notes !== "string" ? "" : query.data.notes;
    console.log("Inited:", {
      "query.data": query.data,
      "initializedRef.current": initializedRef.current,
      initNote: initNote,
    });
    setNote(initNote);
    setIsInitialized(true);
    initializedRef.current = true;
  }, [query.data, query.isFetching]);
  const isSaved = query.data?.notes === note;
  const noteMutation = useNoteMutation();

  const maxLength = 100000;
  const error = note.length > maxLength;

  const handleSave = (e) => {
    e.preventDefault();
    noteMutation.mutate({ notes: note });
  };
  useBlockNavigationPopup({
    isActive: !isSaved,
    title: generalMsg("general.unsaved-changes.title"),
    closeLabel: generalMsg("general.unsaved-changes.close"),
    leaveLabel: generalMsg("general.unsaved-changes.leave"),
  });
  console.log("[NotesPage.rndr]", {
    "query.data?.notes": query.data?.notes,
    note,
    isSaved,
    isInitialized,
    "noteMutation.isPending": noteMutation.isPending,
    "query.isFetching": query.isFetching,
  });

  return (
    <Layout header={{ heading: msg("notes.title") }}>
      <Card>
        <CardContent
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <H2 sx={{ mb: 2 }}>{msg("dashboard.cards.notes.title")}</H2>
          <TextField
            sx={{
              flex: 1,
              width: "100%",
              fontSize: "1rem",
            }}
            variant="outlined"
            multiline
            placeholder={msg("dashboard.cards.notes.placeholder.empty")}
            value={note}
            onChange={(e) => setNote(e.target.value)}
            error={error}
            disabled={!isInitialized}
            helperText={
              <TextFieldHelperText
                charCounter={{ value: note.length, max: maxLength }}
                fieldErrorElement={
                  !error
                    ? null
                    : validationMsg("dict.validation.maxLength", {
                        lteLength: maxLength,
                      })
                }
              />
            }
          />

          <CircularProgress
            color="inherit"
            size={20}
            sx={{
              position: "absolute",
              top: 16,
              right: 16,
              opacity: noteMutation.isPending
                ? 0.15
                : query.isFetching
                ? 0.1
                : 0,
            }}
          />
        </CardContent>
        <LoadingButton
          variant="contained"
          sx={{ m: 2 }}
          disabled={error || noteMutation.isPending || !isInitialized}
          onClick={handleSave}
          loading={noteMutation.isPending || !isInitialized}
          loadingPosition="start"
          startIcon={<Icon name={isSaved ? "Check" : "ArrowUpward"} />}
        >
          {!isInitialized
            ? generalMsg("general.loading")
            : isSaved
            ? generalMsg("general.saved")
            : generalMsg("general.save")}
        </LoadingButton>
      </Card>
    </Layout>
  );
};
