import * as qs from "qs";

export const getInit = ({ method, data }) => {
  const isFormData = data instanceof FormData;
  return {
    method,
    headers: [
      ...(isFormData
        ? []
        : [
            ["Accept", "application/json"],
            ["Content-Type", "application/json"],
          ]),
      ["Access-Control-Allow-Origin", "*"],
    ],
    // credentials: "include", // TODO: test
    // mode: "no-cors", // "cors" | "navigate" | "no-cors" | "same-origin";
    ...(isFormData
      ? { body: data }
      : data
      ? { body: JSON.stringify(data) }
      : {}),
  };
};

const hasContentType = (type, res) =>
  res.headers.get("content-type")?.includes(type);

export const parseResponse = async (response) => {
  let jsonMaybe, error, textMaybe;
  try {
    if (hasContentType("text/html", response))
      textMaybe = await response.text();
    else if (hasContentType("application/json", response))
      jsonMaybe = await response.json();
  } catch (e) {
    error = e;
  }
  return { jsonMaybe, error, textMaybe };
};

export const qstr = (url, query) => {
  if (!query) return url;
  const qStr = typeof query === "string" ? query : qs.stringify(query);
  return [url, qStr].filter(Boolean).join("?");
};
