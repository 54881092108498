import { Box, Divider } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { evolve, move, prop } from "ramda";
import { useCallback, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { ErrorBoundary } from "../../components/ErrorBoundary";
import { Layout, useRightMenu } from "../../components/Layout";
import { ListDnD } from "../../components/ListDnD/ListDnD";
import { MsgProvider } from "../../components/Msg";
import { useMsg } from "../../components/Msg/Msg";
import { H1, P } from "../../components/Typography";
import { routes } from "../../routes";
import { primary25 } from "../../theme";
import { I18nContext } from "../I18n/I18nProvider";
import { Loaders, QueryRenderer } from "../QM/QueryRenderer";
import { controlsMessages } from "../Sessions/steps/Controls";
import {
  useFeedbackNotificationsQuery,
  useFeedbackResultsQuery,
  useUpdateFeedbackFormMutation,
} from "./api";
import { FeedbackResultsSummaryCard } from "./FeedbackResultsSummary/FeedbackResultsSummaryCard";
import { FeedbackRightMenu } from "./FeedbackRightMenu";
import { getCollectedMaybe } from "./GetFeedback.page";
import { messages } from "./messages";
import {
  ReminderStatus,
  ReminderStatusExamples,
} from "./ReminderStatus/ReminderStatus";
import { FieldResultsCard } from "./Results";
import { SharedWith } from "./SharedWith/SharedWith";
import { useDevMode } from "../../hooks/useDevMode";

const SUMMARY_MOCK = {
  strongAreas:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  areasOfImprovement: "areasOfImprovement",
};

const DateStats = ({ feedbackQuery, feedbackId, sx = {} }) => {
  const msg = useMsg({ dict: messages });
  const { i18n } = useContext(I18nContext);
  const query = useFeedbackNotificationsQuery({ feedbackId });
  const sharedAt = query.data?.feedbackFormEmailTime;
  const validTo = feedbackQuery.data?.validTo;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5, ...sx }}>
      {/* <Divider /> */}
      {!!sharedAt && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <P>{msg("feedback.results.published")}</P>
          <P>{i18n.formatLocalMaybe(sharedAt, "Pp")}</P>
        </Box>
      )}
      {!!validTo && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <P>{msg("feedback.results.validTo")}</P>
          <P>{i18n.formatLocalMaybe(validTo, "Pp")}</P>
        </Box>
      )}
      {/* <Divider /> */}
    </Box>
  );
};

function FeedbackResultsPageInner() {
  const { id } = useParams();
  const msg = useMsg();
  const queryClient = useQueryClient();
  const query = useFeedbackResultsQuery({ params: { id } });
  const moveMutation = useUpdateFeedbackFormMutation({
    params: { id },
    // make sure to return the Promise from the query invalidation
    // so that the mutation stays in `pending` state until the refetch is finished
    onSettled: async () => {
      return await queryClient.invalidateQueries({
        queryKey: ["feedback", "results", id],
      });
    },
  });
  const controlsMsg = useMsg({ dict: controlsMessages });
  const [isDevMode] = useDevMode();

  useRightMenu(
    useMemo(() => {
      return (
        <FeedbackRightMenu
          isPending={!query.data}
          collected={getCollectedMaybe(query.data)}
          stats={[
            {
              label: msg("feedback.respondents"),
              value: query.data?.recipients?.length || 0,
            },
            {
              label: msg("feedback.submitted"),
              value:
                query.data?.recipients?.filter(prop("submitted"))?.length || 0,
            },
          ]}
        >
          <DateStats feedbackId={id} feedbackQuery={query} sx={{ pt: 3 }} />
          <ErrorBoundary fallbackRender={() => null}>
            <Divider sx={{ my: 3 }} />
            <ReminderStatus feedbackId={id} />
          </ErrorBoundary>

          <Divider sx={{ my: 3 }} />
          <SharedWith feedback={query.data} />
          <Divider sx={{ my: 3 }} />

          {isDevMode && (
            <>
              <ReminderStatusExamples color="primary" />
              <ReminderStatusExamples color="secondary" />
              <ReminderStatusExamples color="info" />
            </>
          )}
        </FeedbackRightMenu>
      );
    }, [id, isDevMode, msg, query.data])
  );
  const handleMove = useCallback(
    ({ sourceIndex, destinationIndex, feedback }) => {
      const newFeedback = evolve({
        questions: move(sourceIndex, destinationIndex),
      })(feedback);
      console.log("[FeedbackResultsPageInner.handleMove]", {
        sourceIndex,
        destinationIndex,
        feedback,
        newFeedback,
      });
      moveMutation.mutate(newFeedback);
    },
    [moveMutation]
  );

  return (
    <Layout
      header={{
        back: { href: routes.getFeedback, text: controlsMsg("controls.back") },
      }}
      initialPrintLeftMenuHidden
    >
      <QueryRenderer
        alwaysSuccess={
          moveMutation.isPending ? { data: moveMutation.variables } : undefined
        }
        query={query}
        loaderEl={<Loaders.CircularBlock spaced />}
        success={({ data }) => {
          return (
            <Box
              sx={{
                opacity: moveMutation.isPending ? 0.5 : 1,
                display: "flex",
                flexDirection: "column",
                gap: 3,
              }}
            >
              <H1>{data?.title}</H1>
              <P>{data?.description}</P>
              <FeedbackResultsSummaryCard
                summary={data?.summary}
                // summary={SUMMARY_MOCK}
              />
              <ListDnD.Wrapper
                onDragEnd={(result) => {
                  const { source, destination } = result;
                  if (destination)
                    handleMove({
                      sourceIndex: source.index,
                      destinationIndex: destination.index,
                      feedback: data,
                    });
                }}
                sx={{ display: "flex", flexDirection: "column", gap: 3 }}
              >
                {data?.questions?.map((question, index) => (
                  <ListDnD.Item
                    key={question.id ?? JSON.stringify(question)}
                    draggableId={question.id ?? JSON.stringify(question)}
                    index={index}
                    isDragDisabled={
                      !isDevMode ||
                      data?.questions?.length < 2 ||
                      moveMutation.isPending
                    }
                  >
                    <FieldResultsCard
                      key={question.id ?? JSON.stringify(question)}
                      index={index}
                      question={question}
                      feedback={data}
                      sx={{ bgcolor: isDevMode ? primary25 : undefined }} // TODO: remove
                    />
                  </ListDnD.Item>
                ))}
              </ListDnD.Wrapper>
            </Box>
          );
        }}
      />
    </Layout>
  );
}

export function FeedbackResultsPage() {
  return (
    <MsgProvider messages={messages}>
      <FeedbackResultsPageInner />
    </MsgProvider>
  );
}
