import { defineMessages } from "react-intl";

export const generalMessages = defineMessages({
  "general.notifications": {
    id: "general.notifications",
    defaultMessage: "Notifications",
  },
  "general.new-message-from": {
    id: "general.new-message-from",
    defaultMessage: "New message from {from}",
  },
  "general.message-from": {
    id: "general.message-from",
    defaultMessage: "Message from {from}",
  },
  "general.edit": {
    id: "general.edit",
    defaultMessage: "Edit",
  },
  "general.copy": {
    id: "general.copy",
    defaultMessage: "Copy",
  },
  "general.delete": {
    id: "general.delete",
    defaultMessage: "Delete",
  },
  "general.cancel": {
    id: "general.cancel",
    defaultMessage: "Cancel",
  },
  "general.decline": {
    id: "general.decline",
    defaultMessage: "Decline",
  },
  "general.export.csv": {
    id: "general.export.csv",
    defaultMessage: "Export CSV",
  },
  "general.delimiter": {
    id: "general.delimiter",
    defaultMessage: "Delimiter {delimiter}",
  },
  "general.today": {
    id: "general.today",
    defaultMessage: "Today",
  },
  "general.back": {
    id: "general.back",
    defaultMessage: "Back",
  },
  "general.submit": {
    id: "general.submit",
    defaultMessage: "Submit",
  },
  "general.close": {
    id: "general.close",
    defaultMessage: "Close",
  },
  "general.private": {
    id: "general.private",
    defaultMessage: "Private",
  },
  "general.draft": {
    id: "general.draft",
    defaultMessage: "Draft",
  },
  "general.save": {
    id: "general.save",
    defaultMessage: "Save",
  },
  "general.saved": {
    id: "general.saved",
    defaultMessage: "Saved",
  },
  "general.save-draft": {
    id: "general.save-draft",
    defaultMessage: "Save draft",
  },
  "general.or": {
    id: "general.or",
    defaultMessage: "or",
  },
  "general.loading": {
    id: "general.loading",
    defaultMessage: "Loading",
  },
  "general.unsaved-changes.title": {
    id: "general.unsaved-changes.title",
    defaultMessage: "You have unsaved changes",
  },
  "general.unsaved-changes.close": {
    id: "general.unsaved-changes.close",
    defaultMessage: "Return back",
  },
  "general.unsaved-changes.leave": {
    id: "general.unsaved-changes.leave",
    defaultMessage: "Leave anyway",
  },
});
