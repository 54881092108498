import { Box } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Layout, useRightMenu } from "../../components/Layout";
import { MsgProvider } from "../../components/Msg";
import { useMsg } from "../../components/Msg/Msg";
import { routes } from "../../routes";
import { useAuth } from "../Authorization";
import { useClientsQuery } from "../Clients/api";
import { formatName } from "../Coaches/coach.utils";
import { EmptyTemplate } from "../Feedback/EmptyTemplate";
import { Loaders, QueryRenderer } from "../QM/QueryRenderer";
import { MessagesRightMenu } from "./MessagesRightMenu";
import { useConversationsQuery } from "./api";
import { messages } from "./messages";
import { ContactList } from "./ContactList";
import { Conversation } from "./Conversation";

const useCoachClients = () => {
  const { isCoach } = useAuth();
  const query = useClientsQuery({ enabled: isCoach });

  if (!isCoach || !query.data) return [];
  else return query.data;
};

// Requirement: show also clients that haven't been contacted yet
// temp until api will be ready
const useAddCoachClients = () => {
  const coachClients = useCoachClients();

  return useCallback(
    ({ conversations = [] }) => {
      const uncontactedClientsConversations = coachClients
        .filter(
          ({ username }) => !conversations.some((c) => c.username === username)
        )
        .map(({ username, firstName, lastName }) => ({
          username,
          firstName,
          lastName,
          lastMessage: "",
          time: "",
          unreadMessageCount: 0,
        }));

      return [...conversations, ...uncontactedClientsConversations];
    },
    [coachClients]
  );
};

const arr = [];

function MessagesPageInner() {
  const msg = useMsg();
  // cannot destructure, state is null initially
  const { state } = useLocation();
  const routeStateUsername = state?.messagesFrom;

  const [_selectedUsername, setSelectedUsername] = useState();
  const conversationsQuery = useConversationsQuery();

  const addClients = useAddCoachClients();
  const conversations = addClients({
    conversations: conversationsQuery.data ?? arr,
  });

  const selectedUsername = useMemo(() => {
    // When username is not among conversations usernames:
    const usernameCandidates = [
      _selectedUsername,
      routeStateUsername,
      conversations?.[0]?.username,
    ];
    const selectedUsername = usernameCandidates.find((candidate) =>
      conversations.some(({ username }) => username === candidate)
    );
    return selectedUsername;
  }, [_selectedUsername, conversations, routeStateUsername]);

  const selectedConversation = useMemo(() => {
    return conversations.find(({ username }) => username === selectedUsername);
  }, [conversations, selectedUsername]);

  const onSelect = useCallback(
    (key) => {
      console.log("onSelect", { key });
      setSelectedUsername(key);
    },
    [setSelectedUsername]
  );

  console.log("[MessagesPageInner.rndr]", {
    _selectedUsername,
    selectedUsername,
    conversations,
    selectedConversation,
    state,
    conversationsQuery,
  });

  useRightMenu(
    useCallback(
      ({ rightOpen, setRightOpen }) =>
        selectedUsername && (
          <MessagesRightMenu
            username={selectedUsername}
            msg={msg}
            rightOpen={rightOpen}
            setRightOpen={setRightOpen}
          />
        ),
      [msg, selectedUsername]
    )
  );

  return (
    <Layout
      header={{
        heading: msg("messages.heading"),
      }}
    >
      <QueryRenderer
        loaderEl={<Loaders.CircularBlock spaced />}
        query={conversationsQuery}
        success={({ data = [] }) => {
          const conversations = addClients({ conversations: data });
          if (!conversations?.length)
            return (
              <EmptyTemplate
                title={msg("messages.empty.title")}
                description={msg("messages.empty.description")}
                iconName="ChatBubbleOutlineOutlined"
                button={{
                  variant: "contained",
                  children: msg("messages.empty.create-btn"),
                  href: routes.coaches,
                }}
              />
            );
          else
            return (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "stretch",
                  height: "calc(100% - 103px)",
                  flexDirection: { xs: "column", sm: "row" },
                  gap: { xs: 2, sm: 0 },
                }}
              >
                <ContactList
                  conversations={conversations}
                  selectedUsername={selectedUsername}
                  onSelect={onSelect}
                />
                <Conversation
                  addressee={selectedConversation?.username}
                  name={formatName(selectedConversation)}
                />
              </Box>
            );
        }}
      />
    </Layout>
  );
}

export function MessagesPage() {
  return (
    <MsgProvider messages={messages}>
      <MessagesPageInner />
    </MsgProvider>
  );
}
