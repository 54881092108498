import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { isToday, isValid } from "date-fns";
import { useContext } from "react";
import { UserAvatar } from "../../components/Avatar/UserAvatar";
import { formatName } from "../Coaches/coach.utils";
import { I18nContext } from "../I18n/I18nProvider";

// https://mui.com/material-ui/react-list/
export const ContactList = ({
  conversations = [],
  selectedUsername,
  onSelect,
}) => {
  const { i18n } = useContext(I18nContext);
  return (
    <List
      sx={{
        width: "100%",
        maxWidth: { sm: 150, md: 300 },
        bgcolor: "background.paper",
        borderRadius: "6px 0px 0px 6px",
        borderRight: "1px solid #EAECF0",
        overflow: "auto",
        // borderRadius: 0.6,
        // height: "100%",
      }}
    >
      {conversations.map(
        ({
          username,
          firstName,
          lastName,
          fullName = formatName({ firstName, lastName }),
          lastMessage,
          time,
          unreadMessageCount,
        }) => (
          <ListItem
            key={username}
            disablePadding
            alignItems="flex-start"
            sx={{
              bgcolor:
                username === selectedUsername ? "#F9F8FF" : "transparent",
              cursor: "pointer",
            }}
            // secondaryAction={ <IconButton edge="end" aria-label="comments"> <CommentIcon /> </IconButton> }
          >
            <ListItemButton
              role={undefined}
              onClick={() => onSelect(username)}
              dense
            >
              <ListItemAvatar>
                <UserAvatar username={username} fullName={fullName} />
              </ListItemAvatar>
              <ListItemText
                // disableTypography
                // primary={<H2>{username}</H2>}
                // secondary={<span style={{}}>{lastMessage}</span>}
                primaryTypographyProps={{
                  variant: "h2",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                secondaryTypographyProps={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                primary={fullName || username}
                secondary={lastMessage}
                title={username}
                sx={{ width: "100%" }}
              />
              <ListItemText
                primary={
                  isToday(time)
                    ? i18n.formatLocal(time, "p")
                    : isValid(time)
                    ? i18n.formatLocal(time, "P")
                    : // .replace(getYear(new Date()).toString(), "")
                      // ? i18n.formatRelativeLocal(time)
                      ""
                }
                secondary={
                  <Box
                    component={"span"}
                    borderRadius="6px"
                    py={"2px"}
                    px={"6px"}
                    bgcolor={"#F9F8FF"}
                    color={"primary.main"}
                    fontWeight={500}
                    fontSize={"14px"}
                    lineHeight={"21px"}
                    visibility={unreadMessageCount ? "visible" : "hidden"}
                  >
                    {unreadMessageCount}
                  </Box>
                }
                sx={{
                  textAlign: "center",
                  minWidth: "unset",
                  // display: "none", md: undefined },
                }}
              />
            </ListItemButton>
          </ListItem>
        )
      )}
    </List>
  );
};
