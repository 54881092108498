import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import {
  useMyMutation,
  useMyQuery,
} from "../../Authorization/reactQueryWrappers";
import { concat, pipe, reject } from "ramda";
import { useStorage } from "../../../hooks/useStorage";
import { useEffect, useRef } from "react";
import { useAuth } from "../../Authorization";
import { useDevMode } from "../../../hooks/useDevMode";

const FAVORITES_QUERY_KEY = ["coaches", "favorites"];

const useFavoriteCoachesQuery = (rest = {}) => {
  console.log("useFavoriteCoachesQuery", rest);
  return useMyQuery({
    queryKey: FAVORITES_QUERY_KEY,
    fetchDef: {
      url: `/api/latest/coach-favorite`,
    },
    ...rest,
  });
};

export const useIsFavoritesLocalMode = () => {
  const [isDevMode] = useDevMode();
  return isDevMode;
};

const useInvalidateFavorites = () => {
  const queryClient = useQueryClient();
  return async () => {
    return await queryClient.invalidateQueries({
      queryKey: FAVORITES_QUERY_KEY,
    });
  };
};

const createAdd = concat;
const createRemove = (remove) => reject((item) => remove.includes(item));

const useFavoritesOptimisticUpdateFn = () => {
  const queryClient = useQueryClient();
  return async ({ add = [], remove = [] }) => {
    await queryClient.cancelQueries({ queryKey: FAVORITES_QUERY_KEY });
    const previousQueryData = queryClient.getQueryData(FAVORITES_QUERY_KEY);
    const addFn = createAdd(add);
    const removeFn = createRemove(remove);

    // Optimistically update to the new value
    queryClient.setQueryData(FAVORITES_QUERY_KEY, (old) => {
      console.log("useFavoritesOptimisticUpdateFn", { add, remove });
      const newData = pipe(addFn, removeFn)(old);
      return newData;
    });

    return {
      // undo: pipe(createRemove(add), createAdd(remove)),
      previousQueryData,
    };
  };
};

const useAddFavoriteCoachesMutation = ({ id, ...rest } = {}) => {
  const optimisticUpdateFn = useFavoritesOptimisticUpdateFn();
  const invalidateFavorites = useInvalidateFavorites();
  return useMyMutation({
    mutationKey: ["coaches", "favorites", "add", id],
    fetchDef: {
      url: `/api/latest/coach-favorite`,
      method: "POST",
    },
    onMutate: (usernames) => optimisticUpdateFn({ add: usernames }),
    onSettled: invalidateFavorites,
    ...rest,
  });
};

const useRemoveFavoriteCoachMutation = ({ id, ...rest } = {}) => {
  const optimisticUpdateFn = useFavoritesOptimisticUpdateFn();
  const invalidateFavorites = useInvalidateFavorites();
  return useMyMutation({
    mutationKey: ["coaches", "favorites", "remove", id],
    fetchDef: {
      getUrl: (username) => `/api/latest/coach-favorite/${username}`,
      method: "DELETE",
    },
    onMutate: (username) => optimisticUpdateFn({ remove: [username] }),
    onSettled: invalidateFavorites,
    ...rest,
  });
};

const MULTIPLE_FAVORITES_ID = "multiple";
const FAVORITES_STORAGE_KEY = "_____favoriteCoaches";
export const useSyncLocalFavoritesToAPI = () => {
  const isLocalMode = useIsFavoritesLocalMode();
  const {
    emulatedUseState: [localFavorites, setLocalFavorites],
  } = useStorage({
    key: FAVORITES_STORAGE_KEY,
    defaultValue: [],
    storage: localStorage,
  });
  const query = useFavoriteCoachesQuery({ enabled: !isLocalMode });
  const addMutation = useAddFavoriteCoachesMutation({
    id: MULTIPLE_FAVORITES_ID,
    onSuccess: () => {
      setLocalFavorites([]);
    },
  });

  console.log("useSyncLocalFavoritesToAPI", {
    enabled: !isLocalMode,
    localFavorites,
    apiFavoritesMaybe: query.data,
    addMutation,
  });

  useEffect(() => {
    if (isLocalMode) return;
    if (!query.data || addMutation.isPending) return;
    if (query.data?.length === 0 && localFavorites.length) {
      addMutation.mutate(localFavorites);
    }
  }, [addMutation, isLocalMode, localFavorites, query.data]);
};

const useLocalFavorites = () => {
  const {
    emulatedUseState: [favorites, setFavorites],
  } = useStorage({
    key: FAVORITES_STORAGE_KEY,
    defaultValue: [],
    storage: localStorage,
  });

  return {
    favoritesMaybe: favorites,
    addCoach: (username) => setFavorites((prev) => [...prev, username]),
    removeCoach: (username) =>
      setFavorites((prev) => prev.filter((u) => u !== username)),
    mutationPending: false,
  };
};

const useApiFavorites = ({ id }) => {
  const query = useFavoriteCoachesQuery();
  const addMutation = useAddFavoriteCoachesMutation({ id });
  const removeMutation = useRemoveFavoriteCoachMutation({ id });
  // When component using this hook is remounted, local mutation pending states are not preserved
  const addPending =
    useIsMutating({
      mutationKey: ["coaches", "favorites", "add", id],
    }) > 0;
  const removePending =
    useIsMutating({
      mutationKey: ["coaches", "favorites", "remove", id],
    }) > 0;
  const multipleAddPending =
    useIsMutating({
      mutationKey: ["coaches", "favorites", "add", MULTIPLE_FAVORITES_ID],
    }) > 0;
  const remountedButPending = addPending || removePending || multipleAddPending;

  return {
    favoritesMaybe: query.data,
    addCoach: (username) => addMutation.mutate([username]),
    removeCoach: (username) => removeMutation.mutate(username),
    mutationPending: remountedButPending,
  };
};

export const useFavorites = ({ id }) => {
  const isLocalMode = useIsFavoritesLocalMode();
  const { current: useFavorites } = useRef(
    isLocalMode ? useLocalFavorites : useApiFavorites
  );
  console.log("useFavorites", { isLocalMode, useFavorites });
  return useFavorites({ id });
};

const SyncLocalFavoritesInner = () => {
  useSyncLocalFavoritesToAPI();
  return null;
};

export const SyncLocalFavorites = () => {
  let { isLoggedIn } = useAuth();

  if (!isLoggedIn) return null;
  else return <SyncLocalFavoritesInner />;
};
