import { Box } from "@mui/material";
import { Icon } from "../../components/Icon";
import { useMsg } from "../../components/Msg/Msg";
import { coachesMessages } from "./messages";

export const IntroLink = ({ webLink }) => {
  const msg = useMsg({ dict: coachesMessages });

  if (!webLink) return null;

  return (
    <Box
      component={"a"}
      target="_blank"
      rel="noopener noreferrer"
      href={webLink}
      sx={{
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        bgcolor: "#101828CC",
        color: "white",
        py: 1,
        textAlign: "center",
        fontSize: 14,
        textDecoration: "none",
      }}
    >
      {msg("coaches.coach.introduction-link")}&nbsp;
      <Icon name={"OpenInNew"} sx={{ fontSize: 14 }} />
    </Box>
  );
};
