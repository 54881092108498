import { useQuery } from "@tanstack/react-query";
import { useMyMutation } from "../features/Authorization/AuthProvider";

export const useStorageQuery = ({ key, defaultValue, storage }) => {
  return useQuery({
    queryKey: ["local-storage", key],
    queryFn: async () => {
      if (typeof window === "undefined") {
        return defaultValue;
      }
      try {
        const item = storage.getItem(key);
        const value = item ? JSON.parse(item) : defaultValue;
        return value;
      } catch (error) {
        console.log("[useStorageQuery.error]", key, error);
        debugger;
        return defaultValue;
      }
    },
    initialData: defaultValue,
  });
};

export const useLocalStorageMutation = ({ key, storage }) => {
  return useMyMutation({
    // debug: true,
    mutationFn: (value) => {
      if (typeof window === "undefined") return value;

      if (value === undefined) {
        storage.removeItem(key);
      } else {
        const serialized = JSON.stringify(value);
        storage.setItem(key, serialized);
        return JSON.parse(serialized);
      }
    },
    invalidate: { queryKey: ["local-storage", key] },
  });
};

export const useStorage = ({ key, defaultValue, storage = sessionStorage }) => {
  const query = useStorageQuery({ key, defaultValue, storage });
  const mutation = useLocalStorageMutation({ key, storage });
  const emulatedSetState = (valueOrUpdater) => {
    const value =
      typeof valueOrUpdater === "function"
        ? valueOrUpdater(query.data)
        : valueOrUpdater;
    mutation.mutate(value);
  };

  return { query, mutation, emulatedUseState: [query.data, emulatedSetState] };
};
