import { Fade } from "@mui/material";
import {
  any,
  curryN,
  defaultTo,
  find,
  fromPairs,
  map,
  mergeRight,
  nth,
  pipe,
  propEq,
  reject,
  toString,
} from "ramda";
import { ErrorBoundary } from "../ErrorBoundary";
import { useMsg } from "../Msg/Msg";
import { validationMessages } from "./validationMessages";
import * as validations from "./validations";

// <|>
// https://github.com/ramda/ramda/issues/1279
const isValidateName = curryN(2, (validateName, parametrizedValidateItem) =>
  propEq(validateName, 0, parametrizedValidateItem)
);

export const getValidateParamsMaybe = curryN(
  2,
  (validateName, parametrizedValidate = []) =>
    pipe(
      find(isValidateName(validateName)),
      defaultTo([]),
      nth(1)
    )(parametrizedValidate)
);

const getValidate = (validateName, parametrizedValidate) =>
  pipe(
    defaultTo([]),
    find(isValidateName(validateName)),
    defaultTo([])
  )(parametrizedValidate);

export const getRHFRules = ({
  rules = {},
  parametrizedValidate = [],
  // validationDeps,
}) => {
  try {
    // tricky, let's trust to native validation for now
    // also rules.required is used to default clearable and disableClearable
    const isRequired = any(isValidateName("required"), parametrizedValidate);

    const rulesResult = {
      ...rules,
      ...(isRequired ? { required: true } : {}),
      validate: mergeRight(
        rules.validate || {},
        pipe(
          reject(isValidateName("required")), // TODO: {hasValue: (value) => ...} arg by input type?
          map(([key, params]) => [key, validations[key](params)]),
          fromPairs
        )(parametrizedValidate)
      ),
      // deps: validationDeps, // not working
    };

    return rulesResult;
  } catch (e) {
    debugger;
    return {};
  }
};

const getTranslation = ({
  validationKey,
  parametrizedValidate,
  defaultParams = {},
}) => {
  if (!validationKey) return [];
  const [_, params = defaultParams, optionsMaybe] = getValidate(
    validationKey,
    parametrizedValidate
  );
  const tsKey = optionsMaybe?.tsKey || `dict.validation.${validationKey}`;
  const stringParams = map(toString, params); // rePattern -> regexpToMatch throws when passed to react-intl

  return [tsKey, stringParams];
};

const FieldErrorInner = ({
  field,
  fieldState,
  validationKey = fieldState.error?.type, // rules.validate & parametrizedValidate
  rules,
  parametrizedValidate,
  customError,
}) => {
  const msg = useMsg({ dict: validationMessages });

  if (!validationKey) {
    if (customError) return <span>{customError}</span>;
    else return null;
  }

  const [tsKey, tsParams] = getTranslation({
    validationKey,
    parametrizedValidate,
    defaultParams: {
      gteLength: rules?.[validationKey] || "Unknown", // TODO
    },
  });
  const errorText =
    msg.maybe(tsKey, tsParams) || `Error of type: ${validationKey}`;

  // if (true) { error?.ref?.focus?.(); error?.ref?.select?.(); }
  // console.log("[FieldErrorInner.rndr]", { error, msg, name, rules, parametrizedValidate, customError, validationMessages, });
  // debugger;

  return (
    <Fade in={true} timeout={500}>
      <span>{errorText}</span>
    </Fade>
  );
};

export const FieldError = (props) => {
  return (
    <ErrorBoundary>
      <FieldErrorInner {...props} />
    </ErrorBoundary>
  );
};
