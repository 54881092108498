import { pipeP } from "composable-fetch";
import * as tz from "date-fns-tz";
import { formatInTimeZone } from "date-fns-tz/fp";
import {
  addDays,
  addHours,
  addMilliseconds,
  eachWeekOfIntervalWithOptions,
  formatISO,
  getDay,
  isWithinInterval,
  parseISO,
  startOfDay,
} from "date-fns/fp";
import {
  always,
  applySpec,
  filter,
  flatten,
  identity,
  map,
  path,
  pipe,
  prop,
} from "ramda";
import { useContext, useMemo } from "react";
import { useQueries } from "@tanstack/react-query";
import { useAuth } from "../Authorization";
import { useMyMutation } from "../Authorization/AuthProvider";
import { I18nContext } from "../I18n/I18nProvider";
import { fixEnd } from "../I18n/utils/date";

const handleIntervalOverlapping = ({
  overlapping,
  interval,
  parentInterval,
}) => {
  const msg = `[isIntervalWithin] Overlapping interval ${interval.start} - ${interval.end}`;
  switch (overlapping) {
    case true:
      return true;
    case "throw":
      throw new Error(msg);
    case "debugger":
      debugger;
    // eslint-disable-next-line no-fallthrough
    default:
      console.log(`%c${msg}`, "color:magenta", {
        parentInterval,
        interval,
      });
      return false;
  }
};

export const isIntervalWithin =
  ({ parentInterval, overlapping = "throw" }) =>
  (interval) => {
    const startWithin = isWithinInterval(parentInterval, interval.start);
    const endWithin = isWithinInterval(parentInterval, fixEnd(interval.end));
    if (startWithin !== endWithin) {
      return handleIntervalOverlapping({
        overlapping,
        interval,
        parentInterval,
      });
    }
    return startWithin;
  };

const parseLocal = ({ dateStr, timeZone }) => {
  const result = tz.zonedTimeToUtc(dateStr, timeZone);
  if (!dateStr || !timeZone) debugger;
  return result;
};

const parseSlot = (timeZone) => (dateStr) => {
  const start = parseLocal({ timeZone, dateStr });
  const end = fixEnd(addHours(1, start));

  return { start, end, originalApiSlot: dateStr };
};

export const API_DATETIME_LOCAL_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";

const fetchFrameKeyToParams = ({ userTz }) =>
  pipe(
    parseISO,
    applySpec({
      from: pipe(
        startOfDay,
        formatInTimeZone(API_DATETIME_LOCAL_FORMAT, userTz)
      ),
      to: pipe(
        startOfDay,
        addDays(7),
        addMilliseconds(-1),
        formatInTimeZone(API_DATETIME_LOCAL_FORMAT, userTz)
      ),
    })
  );

const getFetchFrameKeys = ({ calendarInterval }) => {
  const now = startOfDay(new Date());
  const floatingWeekKeys = pipe(
    eachWeekOfIntervalWithOptions({ weekStartsOn: getDay(now) }),
    map(formatISO)
  )(calendarInterval);
  return floatingWeekKeys;
};

const fetchAvailabilityWeekIntervals = ({
  authFetch,
  username,
  fetchFrameKey,
  userTz, // fetch frame in user tz? Ask BE
}) => {
  return pipeP(
    fetchFrameKeyToParams({ userTz }),
    applySpec({
      url: always(`/api/latest/coaches/${username}/availability`),
      query: {
        username: always(username),
        from: prop("from"),
        to: prop("to"),
      },
    }),
    authFetch,
    map(parseSlot(userTz))
  )(fetchFrameKey);
};

export const useAvailabilityQueries = ({
  username,
  calendarInterval,
  disabled,
}) => {
  const { authFetch } = useAuth();
  const { userTz } = useContext(I18nContext);
  const fetchFrameKeys = getFetchFrameKeys({ calendarInterval });
  // TODO: frame by current "floating" week, not weekstarts. Initial load - 2x fetch -> 1x fetch
  // console.log(".....", { calendarInterval, fetchFrameKeys });

  const queryDefs = fetchFrameKeys.map((fetchFrameKey) => ({
    enabled: !!username && !disabled,
    queryKey: ["coaches", username, "availability", { userTz, fetchFrameKey }],
    queryFn: async () => ({
      params: { userTz, fetchFrameKey, username, calendarInterval },
      weekData: await fetchAvailabilityWeekIntervals({
        authFetch,
        username,
        fetchFrameKey,
        userTz,
      }),
    }),
  }));

  const queries = useQueries({
    queries: queryDefs,
    combine: (queries) => {
      // react-router > v5, TODO: useMyQuery
      // console.log(">>> COMBINE WORKS", { queries });
      return queries;
    },
  });

  const composedQueries = useMemo(() => {
    const fulfilled = pipe(
      map(path(["data", "weekData"])),
      filter(Boolean) // fulfilled
    )(queries);
    const allIntervalsMaybe =
      fulfilled.length === queries.length ? flatten(fulfilled) : undefined;
    const someIntervals = fulfilled.length ? flatten(fulfilled) : [];

    return {
      queries,
      allResultsQuery: {
        data: allIntervalsMaybe,
        error: queries.find(({ error }) => error),
        isPending: queries.some(({ isPending }) => isPending),
      },
      someResultsQuery: {
        data: someIntervals,
        error: queries.find(({ error }) => error),
        isPending:
          queries.length && queries.every(({ isPending }) => isPending),
      },
    };
  }, [queries]);

  return composedQueries;
};

export const getIsDayLoading = ({ queries, dayInterval, userTz }) => {
  const fetchFrameKeys = getFetchFrameKeys({
    calendarInterval: dayInterval,
    userTz,
  });
  // firstDaysOfTheWeek - one Monday in Europe/Prague can be split in two week starts in UTC
  const allFetched = fetchFrameKeys.every((key) =>
    queries.find((query) => key === query.data?.params?.fetchFrameKey)
  );
  return !allFetched;
};

export const padLeft = (char = "0", num) => {
  const str = `${num}`;
  return str.padStart(2, char);
};

const exampleErr = [
  {
    errorCode: "not.enough.credits",
    fields: [
      {
        name: "user",
        value: "no-credit-user",
      },
    ],
    errorMessage: "User does not have enough credit",
  },
];
export const usePickSlotMutation = ({ username, ...mutationProps }) => {
  const { i18n, userTz } = useContext(I18nContext);
  const pickSlotMutation = useMyMutation({
    fetchDef: {
      method: "POST",
      url: `/api/latest/coaches/${username}/schedule`,
      from: applySpec({
        time: ({ interval, originalApiSlot }) => originalApiSlot,
        // i18n.formatLocal(interval.start, API_DATETIME_LOCAL_FORMAT),
      }),
    },
    snackbar: { success: true, error: true },
    invalidate: [
      { queryKey: ["coaches", username, "availability"] },
      { queryKey: ["user-info"] },
    ],
    ...mutationProps,
  });
  // console.log("[usePickSlotMutation.rndr]", { pickSlotMutation });

  return pickSlotMutation;
};
