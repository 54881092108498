import { Avatar, Box, Button, Divider } from "@mui/material";
import { Icon } from "./Icon";
import { Notifications } from "./Notifications/Notifications";
import { H1, H2 } from "./Typography";

export const Header = ({
  avatarSrc,
  heading,
  noDivider,
  withNotifications,
  actionButton,
  extraElement,
  back,
  sx,
}) => {
  const renderInner = () =>
    back ? (
      <>
        <Button
          color="inherit"
          href={back.href}
          startIcon={<Icon name="ArrowBack" />}
        >
          <H2>{back.text}</H2>
        </Button>
        <Box
          sx={{
            flexGrow: 1,
          }}
        />
      </>
    ) : (
      <H1 sx={{ flexGrow: 1 }}>{heading}</H1>
    );

  return (
    <Box mt={4} mb={3}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          alignItems: "center",
          ...sx,
        }}
      >
        {avatarSrc && (
          <Avatar variant="circular" src={avatarSrc} sx={{ mr: 2 }} />
        )}
        {renderInner()}
        {withNotifications && <Notifications />}
        {actionButton && <Button {...actionButton} />}
        {extraElement}
      </Box>
      {!noDivider && <Divider variant="fullWidth" sx={{ mt: 2, mb: 3 }} />}
    </Box>
  );
};
