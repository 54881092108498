import { useState } from "react";

export const useImgLoading = ({ src }) => {
  const [lastImgSrcLoaded, setLastImgSrcLoaded] = useState();
  const onLoad = () => {
    console.log("[useImgLoading.onLoad] ", src);
    if (src) setLastImgSrcLoaded(src);
  };
  const fadeInOutSx = {
    opacity: src === lastImgSrcLoaded ? 1 : 0,
    transition: "opacity 0.1s ease-in-out",
  };

  return {
    src,
    onLoad,
    sx: fadeInOutSx,
  };
};
