import { Avatar } from "@mui/material";
import { prop } from "ramda";
import { getCoachPhotoUrl } from "../../features/Coaches/coach.utils";
import { useImgLoading } from "../Image/useImgLoading";

const stringToColor = (string) => {
  /* eslint-disable no-bitwise */
  if (!string) return "#000000";
  let hash = 0;
  let i;
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
  /* eslint-enable no-bitwise */
};

export const UserAvatar = ({
  username: usernameProp,
  fullName: fullNameProp,
  sx = {},
}) => {
  const username = usernameProp || "";
  const fullName = fullNameProp || "";
  const src = getCoachPhotoUrl(username);
  const avatarImgProps = useImgLoading({ src });

  return (
    <Avatar
      alt={username}
      src={avatarImgProps.src}
      sx={{
        width: 44,
        height: 44,
        bgcolor: stringToColor(fullName || username),
        ...avatarImgProps.sx,
        ...sx,
      }}
      onLoad={avatarImgProps.onLoad}
    >
      {(fullName || username).split(" ").map(prop(0))}
    </Avatar>
  );
};
