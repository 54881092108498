import {
  Box,
  CircularProgress,
  circularProgressClasses,
  useTheme,
} from "@mui/material";
import { Icon } from "../../../components/Icon";

const CircularProgressGrayedOut = ({
  value = 0,
  size = 40,
  thickness = 4,
  color = "info",
  sx = {},
}) => {
  const theme = useTheme();
  console.log("[CircularProgressGrayedOut.rndr]", {
    value,
    size,
    thickness,
    color,
  });

  return (
    <Box sx={{ position: "relative", ...sx }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: theme.palette.grey[300],
          ...theme.applyStyles("dark", {
            color: theme.palette.grey[800],
          }),
        }}
        size={size}
        thickness={thickness}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        // variant="indeterminate"
        // disableShrink // just for inderetminate
        color={color}
        sx={{
          // color: "#1a90ff",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
          ...theme.applyStyles("dark", {
            // color: "#308fe8",
          }),
        }}
        size={size}
        thickness={thickness}
        value={value}
      />
    </Box>
  );
};

export const IconWithProgress = ({
  value,
  hideProgress = !value,
  size = 24,
  iconSize = "inherit",
  // _iconSize: iconSize = undefined,
  thickness = 3,
  color = "info",
  iconName,
  sx = {},
}) => {
  return (
    <Box sx={{ position: "relative", display: "inline", ...sx }}>
      <CircularProgressGrayedOut
        value={value}
        size={size}
        thickness={thickness}
        color={color}
        sx={{ opacity: hideProgress ? 0 : 1 }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          width: size,
          height: size,
          // bottom: 0,
          // right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: iconSize,
        }}
      >
        <Icon name={iconName} fontSize={iconSize} />
      </Box>
    </Box>
  );
};
