import { useSessionStorage } from "./useLocalStorage";
import { useStorage } from "./useStorage";

export const useDevMode = () => {
  return useSessionStorage("_devMode", false); // needs refresh, consumers not updated
  const { emulatedUseState } = useStorage({
    // initial render contains defaultValue, regardless of storage value
    key: "_devMode",
    defaultValue: false,
    storage: sessionStorage,
  });
  return emulatedUseState;
};
