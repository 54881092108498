import { fromPairs, map, pipe } from "ramda";
import { useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import { primary25 } from "../../theme";

const messages = defineMessages({
  "dict.user_status.authorized.label": {
    id: "dict.user_status.authorized.label",
    defaultMessage: "Authorized",
  },
  "dict.user_status.pending.label": {
    id: "dict.user_status.pending.label",
    defaultMessage: "Pending",
  },
  "dict.user_status.paid.label": {
    id: "dict.user_status.paid.label",
    defaultMessage: "Paid",
  },
  "dict.user_status.canceled.label": {
    id: "dict.user_status.canceled.label",
    defaultMessage: "Canceled",
  },
});

// AUTHORIZED, PENDING, PAID, REQUESTED, VIEWED, SUBMITTED, CANCELED
const USER_STATUS_COLORS = {
  AUTHORIZED: "secondary.light",
  PENDING: "#EAAA084D",
  PAID: "success.light",
  CANCELED: "error.light",
};
const DEFAULT_COLOR = "#FFF";

const userStatusKeys = Object.keys(USER_STATUS_COLORS);

const translateStatus = (intl, key) => {
  const getId = (prop) => `dict.user_status.${key.toLocaleLowerCase()}.${prop}`;

  return {
    key,
    value: key,
    label: intl.formatMessage({ ...messages[getId("label")] }),
    bgcolor: USER_STATUS_COLORS[key] || DEFAULT_COLOR,
  };
};

export const useUserStatusDict = () => {
  const intl = useIntl();
  const userStatusDict = useMemo(
    () =>
      pipe(
        map((k) => [k, translateStatus(intl, k)]),
        fromPairs
      )(userStatusKeys),
    [intl]
  );

  return useMemo(
    () => ({
      userStatusDict,
      userStatusOptions: Object.values(userStatusDict),
    }),
    [userStatusDict]
  );
};
