import { Avatar, Box, Button, CircularProgress } from "@mui/material";
import { FL_MAX_WIDTH } from "../FormRow";
import { H3, P } from "../../../components/Typography";
import { useMsg } from "../../../components/Msg/Msg";
import { settingsMessages } from "../messages";
import { QueryRenderer } from "../../QM/QueryRenderer";
import pjson from "../../../../package.json";
import { useMyQuery } from "../../Authorization/reactQueryWrappers";
import { isDevelopment, isProduction } from "../../../utils/environment";

const getHref = (path, origin) => {
  const url = new URL(path, origin);
  const href = url.href;

  if (isDevelopment()) console.log("[getHref]", { path, origin, url, href });

  return href;
};

// on localhost we need to override origin, other envs can use origin "as is" and benefit from proxy
const getHrefForEnv = (path, developmentOrigin) => {
  const origin = isDevelopment()
    ? developmentOrigin || window.location.origin
    : window.location.origin;
  return getHref(path, origin);
};

const getGCalHref = () => {
  return getHrefForEnv(`/login/google`, pjson?.proxy);
};

// QA:
// https://calendly.com/oauth/authorize?client_id=PJ2FbDI-YAyzEJya2H4RqHv_Sv0F-Hl0d-i93FkAuH8&response_type=code&redirect_uri=https://qa.topleaderplatform.io/login/calendly

// prod:
// https://calendly.com/oauth/authorize?client_id=vnzwTereNrHoVzQbrJMAhQM3KRfRxUPXHNtiQ-R0KJw&response_type=code&redirect_uri=https://qa.topleaderplatform.io/login/calendly

const getCalendlyHref = ({
  clientId = isProduction()
    ? "vnzwTereNrHoVzQbrJMAhQM3KRfRxUPXHNtiQ-R0KJw"
    : "PJ2FbDI-YAyzEJya2H4RqHv_Sv0F-Hl0d-i93FkAuH8",
  redirectUri = getHrefForEnv(
    "/login/calendly",
    "https://qa.topleaderplatform.io" // not working with localhost or proxy
  ),
} = {}) =>
  `https://calendly.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}`;

/* {
  "active": true,
  "status": "IN_PROGRESS",
  "lastSync": "2024-04-28T12:48:19.110Z"
} */
const useGoogleInfoQuery = () => {
  return useMyQuery({
    queryKey: ["google-info"],
    fetchDef: {
      url: "/api/latest/google-info",
    },
  });
};

const useCalendlyInfoQuery = () => {
  return useMyQuery({
    queryKey: ["calendly-info"],
    fetchDef: {
      url: "/api/latest/calendly-info",
    },
  });
};

export const GCalSync = ({ msg }) => {
  const query = useGoogleInfoQuery();

  return (
    <>
      <QueryRenderer
        query={query}
        loading={() => <CircularProgress size={20} color="primary" />}
        success={({ data }) => {
          if (!data?.active)
            return (
              <Button
                component="a"
                variant="outlined"
                href={getGCalHref()}
                // target="_blank"
              >
                {msg("settings.general.integrations.google-calendar.connect")}
              </Button>
            );
          else
            return (
              <Button
                component="a"
                variant="outlined"
                href={getGCalHref()}
                title={data?.status}
                // target="_blank"
              >
                {msg("settings.general.integrations.google-calendar.reconnect")}
              </Button>
            );
        }}
      />
    </>
  );
};

export const CalendlySync = ({ msg }) => {
  const query = useCalendlyInfoQuery();

  return (
    <>
      <QueryRenderer
        query={query}
        loading={() => <CircularProgress size={20} color="primary" />}
        success={({ data }) => {
          if (!data?.active)
            return (
              <Button
                component="a"
                variant="outlined"
                href={getCalendlyHref({})}
                // target="_blank"
              >
                {msg("settings.general.integrations.calendly.connect")}
              </Button>
            );
          else
            return (
              <Button
                component="a"
                variant="outlined"
                href={getGCalHref()}
                title={data?.status}
                // target="_blank"
              >
                {msg("settings.general.integrations.google-calendar.reconnect")}
              </Button>
            );
        }}
      />
    </>
  );
};

export const Integration = ({
  avatarSrc,
  title,
  description,
  ButtonComponent,
}) => {
  const msg = useMsg({ dict: settingsMessages });
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        maxWidth: FL_MAX_WIDTH,
      }}
    >
      <Avatar
        sx={{ width: 44, height: 44, mr: 2 }}
        variant="square"
        src={avatarSrc}
      />
      <Box sx={{ flexGrow: "2" }}>
        <H3 sx={{ mb: 1 }}>{title}</H3>
        <P>{description}</P>
      </Box>
      <ButtonComponent msg={msg} />
    </Box>
  );
};
