import { useState } from "react";
import { intersperse, pipe, split } from "ramda";
import { Button } from "@mui/material";
import { nonbreakableSubstr } from "../features/Coaches/nonbreakableSubstr";

// dangerouslySetInnerHTML is not safe, can we trust it?
export const renderSafeWithBreaks = pipe(
  split("\n"),
  intersperse(<br />),
  (arr) => <>{arr}</>
);
export const ShowMore = ({
  text: textProp,
  maxChars = 1000,
  moreTranslation = "Show more",
  initialShowAll = false,
}) => {
  const [isMore, setIsMore] = useState(initialShowAll);
  // const text = (textProp || "").replace(/(\\n){2,}/, "\n"); // TODO
  const text = textProp || "";
  const elipsis = "... ";
  const maxCharsWithOffset =
    maxChars + elipsis.length + (moreTranslation?.length || 0);
  const canFit = text.replaceAll("\n", "").length <= maxCharsWithOffset;

  const withShowMore = (text) => (
    <>
      {renderSafeWithBreaks(text)}
      {elipsis}
      <Button
        variant="text"
        sx={{ lineHeight: 1 }}
        onClick={() => setIsMore(true)}
        disableFocusRipple
        disableRipple
      >
        {moreTranslation}
      </Button>
    </>
  );

  return isMore || canFit
    ? renderSafeWithBreaks(text)
    : withShowMore(nonbreakableSubstr("\n", maxChars, text));
};
