import { Box, CardContent, Chip } from "@mui/material";
import { descend, find, join, pipe, prop, sort, uniq } from "ramda";
import React, { useMemo } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { ChipsCard } from "../../components/ChipsCard";
import { HistoryRightMenu } from "../../components/HistoryRightMenu";
import { Icon } from "../../components/Icon";
import { Layout } from "../../components/Layout";
import { Msg, MsgProvider } from "../../components/Msg";
import { H1, P } from "../../components/Typography";
import { routes } from "../../routes";
import { useMyQuery } from "../Authorization/AuthProvider";
import { Loaders, QueryRenderer } from "../QM/QueryRenderer";
import { useMakeSelectable } from "../Values/MyValues";
import { SelectedStregth } from "./SelectedStregth";
import { messages } from "./messages";
import { useAllTalentsDict } from "./talents";
import { DownloadData } from "../../components/DownloadData/DownloadData";
import { useMsg } from "../../components/Msg/Msg";

const useStrengthsHistoryQuery = () =>
  useMyQuery({
    queryKey: ["strengths"],
    fetchDef: { url: "/api/latest/history/STRENGTHS" },
  });

const talentsCategories = [
  {
    range: [0, 5],
    nameTsKey: "strengths.first.name",
    summaryTsKey: "strengths.first.summary",
    chip: (
      <Chip
        sx={{ borderRadius: 0.5 }}
        label="Top"
        icon={<Icon name="Star" />}
        size="small"
        color="warning"
      />
    ),
  },
  {
    range: [5, 10],
    nameTsKey: "strengths.second.name",
    summaryTsKey: "strengths.second.summary",
  },
  {
    range: [10, Infinity],
    nameTsKey: "strengths.third.name",
    summaryTsKey: "strengths.third.summary",
    positivesHeadingKey: "strengths.positives.title-last",
  },
];

const getCategory = (index) =>
  find(
    ({ range: [start, end] }) =>
      index >= start && (end === undefined || index < end),
    talentsCategories
  );

const staticArray = [];

export function StrengthsPage() {
  const msg = useMsg({ dict: messages });
  const navigate = useNavigate();
  const query = useStrengthsHistoryQuery();
  const sel = useMakeSelectable({
    entries: query.data ?? [],
    sorter: sort(descend(prop("date"))),
    map: (el) => ({
      date: el.createdAt,
      timestamp: new Date(el.createdAt).getTime(),
      orderedTalents: el.data.strengths,
    }),
  });
  const talentsDict = useAllTalentsDict();
  const orderedTalents = sel.selected?.orderedTalents || staticArray;
  const downloadData = useMemo(() => {
    return orderedTalents.map((key, index) => ({
      talent: talentsDict[key]?.name || key,
      category: msg(getCategory(index)?.nameTsKey),
      positives: talentsDict[key]?.positives.join(", "),
      tips: talentsDict[key]?.tips.join(", "),
    }));
  }, [msg, orderedTalents, talentsDict]);
  const downloadVisible = downloadData.some((it) => it.positives || it.tips);

  console.log("[Strengths.rndr]", {
    sel,
    query,
    talentsDict,
  });

  return (
    <MsgProvider messages={messages}>
      <Layout
        header={{
          back: {
            href: routes.dashboard,
            text: <Msg id="strengths.header.back" />,
          },
          extraElement: downloadVisible && (
            <DownloadData
              data={downloadData}
              disabled={query.isPending}
              fileName={`TopLeader-my-strengths-${sel.selected?.date}`}
            />
          ),
        }}
        rightMenuContent={
          <HistoryRightMenu
            heading={<Msg id="strengths.aside.title" />}
            perex={<Msg id="strengths.aside.perex" />}
            history={sel}
            isLoading={query.isPending}
            buttonProps={{
              children: <Msg id="strengths.aside.retake-button" />,
              onClick: () => navigate(routes.assessment),
            }}
          />
        }
      >
        <Box>
          <H1>
            <Msg id="strengths.heading.title" />
          </H1>
          <P mt={1} mb={3}>
            <Msg id="strengths.heading.perex" />
          </P>
          <QueryRenderer
            loaderEl={<Loaders.CircularBlock spaced />}
            query={query}
            success={() => {
              if (!sel.all.length)
                return <Navigate to={routes.assessment} replace />;
              return (
                <>
                  {talentsCategories.map(
                    ({
                      range: [start, end],
                      chip,
                      summaryTsKey,
                      positivesHeadingKey,
                    }) => {
                      const keys = orderedTalents.slice(start, end);
                      if (!keys.length) return null;

                      const from = start + 1;
                      const to = Math.min(end, orderedTalents.length);

                      return (
                        <ChipsCard
                          key={start}
                          keys={keys}
                          dict={talentsDict}
                          renderSummary={() => (
                            <CardContent>
                              <H1 gutterBottom>
                                {pipe(uniq, join("-"))([from, to])}&nbsp;{chip}
                              </H1>
                              <P>{msg(summaryTsKey)}</P>
                            </CardContent>
                          )}
                          isSelectable={
                            (it) => it?.positives?.length || it?.tips?.length // gallup
                          }
                          renderSelected={(selected) => (
                            <SelectedStregth
                              positivesHeading={
                                positivesHeadingKey && msg(positivesHeadingKey)
                              }
                              positives={selected.positives}
                              tips={selected.tips}
                            />
                          )}
                        />
                      );
                    }
                  )}
                </>
              );
            }}
          />
        </Box>
      </Layout>
    </MsgProvider>
  );
}
