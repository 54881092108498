import { defineMessages } from "react-intl";

export const validationMessages = defineMessages({
  "dict.validation.required": {
    id: "dict.validation.required",
    defaultMessage: "Required",
  },
  "dict.validation.notBlank": {
    id: "dict.validation.notBlank",
    defaultMessage: "Cannot be blank",
  },
  "dict.validation.minLength": {
    id: "dict.validation.minLength",
    defaultMessage: "Too short. Min length is {gteLength}",
  },
  "dict.validation.maxLength": {
    id: "dict.validation.maxLength",
    defaultMessage: "Too long. Max length is {lteLength}",
  },
  "dict.validation.validDate": {
    id: "dict.validation.validDate",
    defaultMessage: "Invalid date",
  },
  "dict.validation.forbiddenValues": {
    id: "dict.validation.forbiddenValues",
    defaultMessage: "Forbidden value",
  },
  "dict.validation.notUnique": {
    id: "dict.validation.notUnique",
    defaultMessage: "Must be unique",
  },
  "dict.validation.invalidDate": {
    id: "dict.validation.invalidDate",
    defaultMessage: "Invalid date",
  },
  "dict.validation.invalidTime": {
    id: "dict.validation.invalidTime",
    defaultMessage: "Invalid time",
  },
  "dict.validation.todayOrFuture": {
    id: "dict.validation.todayOrFuture",
    defaultMessage: "Must be in the future",
  },
  "dict.validation.rePattern": {
    id: "dict.validation.rePattern",
    defaultMessage: "Must match {regexpToMatch}",
  },
  "dict.validation.email": {
    id: "dict.validation.email",
    defaultMessage: "Must be valid email",
  },
});
