export const isProduction = () => process.env.REACT_APP_ENV === "PROD";

export const isQA = () => process.env.REACT_APP_ENV === "QA";

export const isDevelopment = () => process.env.REACT_APP_ENV === "DEV";

console.log("[utils/environments.js]", {
  "process.env.NODE_ENV": process.env.NODE_ENV,
  "process.env.REACT_APP_ENV": process.env.REACT_APP_ENV,
  isProduction: isProduction(),
  isQA: isQA(),
  isDevelopment: isDevelopment(),
});
