import { Box } from "@mui/material";
import {
  all,
  applySpec,
  chain,
  curryN,
  either,
  filter,
  identity,
  intersection,
  isEmpty,
  length,
  map,
  pathOr,
  pipe,
  prop,
  uniq,
  values,
} from "ramda";
import { useMemo, useState } from "react";
import { Layout } from "../../components/Layout";
import { MsgProvider } from "../../components/Msg";
import { useMsg } from "../../components/Msg/Msg";
import { useAuth } from "../Authorization";
import { evolveSome } from "../Messages/api";
import { QueryRenderer } from "../QM/QueryRenderer";
import { CoachCard } from "./CoachCard";
import { CoachesFilter, INITIAL_FILTER } from "./CoachesFilter";
import { CoachesRightMenu } from "./CoachesRightMenu";
import { ScheduledSessionsCard } from "./ScheduledSessions";
import {
  useCoachesQuery,
  useUserUpcomingSessionsQuery,
  useYourCoachQuery,
} from "./api";
import { coachesMessages } from "./messages";
import { useFavorites } from "./Favorites/useFavorites";

const hasIntersection = curryN(2, pipe(intersection, length, Boolean));
const allPropsPass = (obj) => pipe(evolveSome(obj), values, all(Boolean));
const filterPredicate = (filter) => (coach) =>
  allPropsPass({
    languages: either(isEmpty, hasIntersection(coach.languages)),
    fields: either(isEmpty, hasIntersection(coach.fields)),
    prices: either(isEmpty, hasIntersection([coach.rate])),
    experience: ([from, to] = []) =>
      (!from || coach.experience >= from) && (!to || coach.experience <= to),
    search: (s) =>
      !s || JSON.stringify(coach).toLowerCase().includes(s.toLowerCase()),
  })(filter);

const allCoachesFilter = INITIAL_FILTER();
const emptyArrayStatic = [];

export function CoachesPageInner() {
  const msg = useMsg({ dict: coachesMessages });
  const [coachesFilter, setCoachesFilter] = useState(INITIAL_FILTER());
  // Currently filtered on FE to be able to filter favorites
  // TODO: discuss BE filtering
  // const coachesQuery = useCoachesQuery({ filter: coachesFilter });
  const allCoachesQuery = useCoachesQuery({
    filter: allCoachesFilter,
  });

  const allPossiblyVisibleCoaches =
    allCoachesQuery.data?.content || emptyArrayStatic;
  const { supportedLanguages, supportedFields } = useMemo(
    () =>
      applySpec({
        supportedLanguages: pipe(chain(prop("languages")), uniq),
        supportedFields: pipe(chain(prop("fields")), uniq),
      })(allPossiblyVisibleCoaches),
    [allPossiblyVisibleCoaches]
  );

  const { favoritesMaybe } = useFavorites({ id: "pending-not-used" });

  return (
    <Layout
      header={{ heading: msg("coaches.heading") }}
      rightMenuContent={<CoachesRightMenu />}
    >
      <CoachesFilter
        filter={coachesFilter}
        setFilter={setCoachesFilter}
        supportedLanguages={supportedLanguages}
        supportedFields={supportedFields}
        favoritesDisplayed
        favoritesDisabled={!favoritesMaybe?.length}
      />
      <QueryRenderer
        query={allCoachesQuery}
        loaderEl={<CoachCard isLoading sx={{ my: 3 }} />}
        success={pipe(
          pathOr([], ["data", "content"]),
          coachesFilter.displayFavorites
            ? filter(({ username }) => favoritesMaybe?.includes?.(username))
            : filter(({ freeSlots }) => freeSlots),
          filter(filterPredicate(coachesFilter)),
          map((coach) => (
            <CoachCard
              key={coach.username}
              coach={coach}
              withContact
              sx={{ my: 3 }}
            />
          ))
        )}
      />
      {/* <QueryRenderer query={coachesQuery} loaderEl={<CoachCard isLoading sx={{ my: 3 }} />} success={({ data: { content = [] } }) => content.map((coach, i) => ( <CoachCard key={coach.username} coach={coach} withContact sx={{ my: 3 }} /> )) } /> */}
      <Box sx={{ height: "1px" }} />
    </Layout>
  );
}

const YourCoachPageInner = () => {
  const msg = useMsg({ dict: coachesMessages });
  const yourCoachQuery = useYourCoachQuery();
  const userUpcomingSessionsQuery = useUserUpcomingSessionsQuery();

  return (
    <Layout
      header={{ heading: msg("your-coach.heading") }}
      rightMenuContent={<CoachesRightMenu />}
    >
      <QueryRenderer
        query={yourCoachQuery}
        loaderEl={<CoachCard isLoading sx={{ my: 3 }} />}
        success={({ data: coach }) => (
          <CoachCard coach={coach} withContact sx={{ my: 3 }} />
        )}
      />
      <QueryRenderer
        query={userUpcomingSessionsQuery}
        loading={() => null}
        success={({ data }) => (
          <ScheduledSessionsCard
            data={data}
            firstRowExtraInfo={msg("your-coach.session-link-info")}
          />
        )}
      />
    </Layout>
  );
};

export function CoachesPage() {
  const { userQuery } = useAuth();

  return (
    <MsgProvider messages={coachesMessages}>
      {userQuery.data.coach ? <YourCoachPageInner /> : <CoachesPageInner />}
    </MsgProvider>
  );
}
