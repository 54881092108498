import { useFormContext } from "react-hook-form";
import { useMsg } from "../../../components/Msg/Msg";
import { P } from "../../../components/Typography";
import { useMyQuery } from "../../Authorization/AuthProvider";
import { ActionSteps, actionStepsMessages } from "./ActionSteps";
import { useArea } from "./AreaStep";
import { Controls } from "./Controls";
import { FormStepCard } from "./FormStepCard";
import { SessionTodosFields } from "./SessionTodos";
import { SESSION_FIELDS } from "./constants";
import { useEffect, useRef } from "react";

const ActionStepsRequiredWhenSomeLeft = ({
  actionStepsHints,
  hintsLoading,
}) => {
  const methods = useFormContext();
  const { watch, trigger } = methods;
  const previousActionSteps = watch("previousActionSteps");

  const newActionRequired =
    previousActionSteps?.filter(({ checked }) => !checked)?.length === 0;
  console.log({
    methods,
    previousActionSteps,
    newActionRequired,
    "previousActionSteps?.filter(({ checked }) => !checked)":
      previousActionSteps?.filter(({ checked }) => !checked),
  });

  const canTriggerValidationRef = useRef(false);
  useEffect(() => {
    if (!canTriggerValidationRef.current) {
      return;
    }
    // Trigger validation whenever rules change
    trigger(SESSION_FIELDS.ACTION_STEPS);
  }, [trigger, newActionRequired]);

  return (
    <ActionSteps
      name={SESSION_FIELDS.ACTION_STEPS}
      rules={
        newActionRequired
          ? { required: true, minLength: 1 }
          : { required: false, minLength: 0 }
      }
      hints={actionStepsHints}
      hintsLoading={hintsLoading}
      onRemove={() => (canTriggerValidationRef.current = true)}
      sx={{ my: 5 }}
    />
  );
};

export const useActionStepsAIHintsQuery = ({
  areaOfDevelopment,
  longTermGoal,
}) => {
  return useMyQuery({
    enabled: !!areaOfDevelopment && !!longTermGoal,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryKey: [
      "user-sessions",
      "generate-action-steps",
      areaOfDevelopment,
      longTermGoal,
    ],
    fetchDef: {
      method: "POST",
      url: `/api/latest/user-sessions/generate-action-steps`,
      payload: { areaOfDevelopment, longTermGoal },
    },
  });
};

export const SetActionStepsStep = ({
  onFinish,
  step,
  stepper,
  data,
  setData,
  handleNext,
  handleBack,
  submitDisabled,
  previousArea,
  previousGoal,
}) => {
  const msg = useMsg({ dict: actionStepsMessages });
  const valueArr = data[SESSION_FIELDS.AREA_OF_DEVELOPMENT] || previousArea;
  const { areaText } = useArea({ valueArr });
  const actionStepsAIHintsQuery = useActionStepsAIHintsQuery({
    areaOfDevelopment: areaText,
    longTermGoal: data[SESSION_FIELDS.LONG_TERM_GOAL] || previousGoal,
  });
  const actionStepsHints = [].concat(actionStepsAIHintsQuery.data || []);
  // const actionStepsHints = ["Hint 1", "Hint 2", "Hint 3"];

  return (
    <FormStepCard
      {...{ step, stepper, data, setData, handleNext, handleBack }}
      renderControls={({
        handleNext,
        handleBack,
        formState,
        data,
        componentData,
      }) => (
        <Controls
          data={{ ...data, ...componentData }}
          handleNext={onFinish}
          handleBack={handleBack}
          nextProps={{
            disabled: !formState.isValid || submitDisabled,
            children: msg("action-steps.done"),
            endIcon: undefined,
          }}
        />
      )}
    >
      <SessionTodosFields name={"previousActionSteps"} />
      <P my={2}></P>
      <ActionStepsRequiredWhenSomeLeft
        actionStepsHints={actionStepsHints}
        hintsLoading={actionStepsAIHintsQuery.isPending}
      />
    </FormStepCard>
  );
};
