import { Box } from "@mui/material";
import { P } from "../../../components/Typography";
import { gray900, primary25 } from "../../../theme";
import { HeadingWithIcon } from "../../Dashboard/HeadingWithIcon";

export const InfoBox = ({ title, text, sx = {} }) => {
  return (
    <Box
      sx={{
        bgcolor: primary25,
        mb: 1,
        borderRadius: "6px",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        p: 2,
        ...sx,
      }}
    >
      <HeadingWithIcon emphasized withoutIcon title={title} />
      <P bigger sx={{ color: gray900 }}>
        {text}
      </P>
    </Box>
  );
};
