import { Box } from "@mui/material";
import { useContext, useEffect, useRef } from "react";
import { P } from "../../components/Typography";
import { I18nContext } from "../I18n/I18nProvider";
import { isToday } from "date-fns";
import { useMsg } from "../../components/Msg/Msg";
import { generalMessages } from "../../components/messages";

export const ConversationMessage = ({ message, scrollIntoView }) => {
  const { i18n } = useContext(I18nContext);
  const generalMsg = useMsg({ dict: generalMessages });
  const ref = useRef();
  const messageRef = useRef(message);
  messageRef.current = message;
  useEffect(() => {
    if (scrollIntoView) {
      console.log("[ConversationMessage.scrollIntoView] should scroll now", {
        ...messageRef.current,
        ref,
      });
      if (ref.current) setTimeout(() => ref.current.scrollIntoView(), 100);
      // TODO: rm timeout - side menu not initially rendered - without timeout this effect executes on "wider screen"
      else
        console.log(
          "[ConversationMessage.scrollIntoView] ref missing, NOT scrolling into view",
          { ...messageRef.current }
        );
    }
  }, [scrollIntoView]);

  const { fromMe, text, createdAt } = message;
  const addresseeSx = fromMe
    ? {
        color: "white",
        bgcolor: "primary.main",
        border: `1px solid #4720B7`,
        // border: `1px solid primary.main`,
        borderBottomRightRadius: 0,
      }
    : {
        color: "#475467",
        bgcolor: "#F9F8FF",
        border: `1px solid #907ACF`,
        borderBottomLeftRadius: 0,
      };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: fromMe ? "flex-end" : "flex-start",
      }}
    >
      <P sx={{ mb: 0.5 }}>
        {i18n.formatLocal(
          createdAt,
          isToday(createdAt) ? `'${generalMsg("general.today")}, 'p` : "Pp"
        )}
      </P>
      <Box
        ref={ref}
        sx={{
          width: "90%",
          maxWidth: "500px",
          borderRadius: "6px",
          p: 2,
          opacity: message.isOptimisticUpdate ? 0.5 : 1,
          ...addresseeSx,
        }}
      >
        {text}
      </Box>
    </Box>
  );
};
