import { Box, CardContent } from "@mui/material";
import { head, identity, map, pipe } from "ramda";
import React, { useCallback, useMemo, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChipsCard } from "../../components/ChipsCard";
import { HistoryRightMenu } from "../../components/HistoryRightMenu";
import { InfoBox } from "../../components/InfoBox";
import { Layout } from "../../components/Layout";
import { Msg, MsgProvider } from "../../components/Msg";
import { H1, P } from "../../components/Typography";
import { useStaticCallback } from "../../hooks/useStaticCallback.hook";
import { routes } from "../../routes";
import { useMyQuery } from "../Authorization/AuthProvider";
import { Loaders, QueryRenderer } from "../QM/QueryRenderer";
import { messages } from "./messages";
import { useValuesDict } from "./values";

// {
//     "id": 1,
//     "username": "em@i.ll",
//     "type": "VALUES",
//     "createdAt": "2023-08-15T17:12:10.640453",
//     "data": {
//         "type": "VALUES_TYPE",
//         "values": [
//             "fairness",
//             "love",
//             "truth",
//             "independence"
//         ]
//     }
// }

const useValuesHistoryQuery = () =>
  useMyQuery({
    queryKey: ["values"],
    fetchDef: { url: "/api/latest/history/VALUES" },
  });

export const REASON = {
  MANUAL: "manual",
  SCROLL: "scroll",
};

export const useMakeSelectable = ({
  entries = [],
  idKey = "timestamp",
  map: mapItem,
  sorter = identity,
  getDefaultSelected = head,
}) => {
  const staticMap = useStaticCallback(mapItem);
  const stack = useMemo(
    () => pipe(map(staticMap), sorter)(entries),
    [entries, sorter, staticMap]
  );
  const [selectedId, setSelectedId] = useState();
  const [reason, setReason] = useState();
  const [selectionId, changeSelectionId] = useReducer((s) => s + 1, 0);
  const selectedWithDefault =
    stack.find((entry) => entry[idKey] === selectedId) ||
    getDefaultSelected(stack);
  const isSelected = useCallback(
    (entry) => selectedWithDefault[idKey] === entry[idKey],
    [idKey, selectedWithDefault]
  );
  const setSelected = useCallback(
    (entry, reason = REASON.MANUAL) => {
      setSelectedId(entry?.[idKey]);
      setReason(reason);
      changeSelectionId();
    },
    [idKey]
  );

  return {
    reason,
    selectedId,
    selectionId,
    all: stack,
    selected: selectedWithDefault,
    setSelected,
    setSelectedId,
    isSelected,
  };
};

export function MyValuesPage() {
  const query = useValuesHistoryQuery();
  const sel = useMakeSelectable({
    entries: query.data ?? [],
    map: (el) => ({
      date: el.createdAt,
      timestamp: new Date(el.createdAt).getTime(),
      selectedKeys: el.data.values,
    }),
  });

  const navigate = useNavigate();
  const valuesDict = useValuesDict();

  console.log("[MyValues.rndr]", {
    query,
    sel,
  });

  // if (!query.data) {
  //   return <Skeleton />;
  // }

  return (
    <MsgProvider messages={messages}>
      <Layout
        header={{
          back: {
            href: routes.dashboard,
            text: <Msg id="values.header.back" />,
          },
        }}
        rightMenuContent={
          <HistoryRightMenu
            heading={<Msg id="values.aside.title" />}
            perex={<Msg id="values.aside.perex" />}
            history={sel}
            // onRemove={history.remove}
            isLoading={query.isPending}
            buttonProps={{
              children: <Msg id="values.aside.save" />,
              onClick: () => navigate(routes.setValues),
            }}
          />
        }
      >
        <Box>
          <H1>
            <Msg id="values.heading" />
          </H1>
          <P mt={1} mb={3}>
            <Msg id="values.perex" />
          </P>
          <QueryRenderer
            loaderEl={<Loaders.CircularBlock spaced />}
            query={query}
            success={() => (
              <ChipsCard
                keys={sel.selected?.selectedKeys}
                dict={valuesDict}
                renderSummary={() => (
                  <CardContent>
                    <P>
                      <Msg id="values.card.summary" />
                    </P>
                  </CardContent>
                )}
                renderSelected={({ name, description }) => (
                  <CardContent>
                    <InfoBox color="primary" heading={name}>
                      {description}
                    </InfoBox>
                  </CardContent>
                )}
              />
            )}
          />
        </Box>
      </Layout>
    </MsgProvider>
  );
}
