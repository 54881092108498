import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

const RightMenuContext = createContext();

const update =
  ({ id, render }) =>
  (prev) => {
    const index = prev.findIndex((item) => item.id === id);
    let newStack;
    if (!render) {
      // console.log("%cUPDATE STACK", "color:pink;", "REMOVE");
      newStack = prev.filter((item) => item.id !== id);
    } else if (index < 0 && render) {
      // console.log("%cUPDATE STACK", "color:pink;", "ADD");
      newStack = [...prev, { id, render }];
    } else {
      // console.log("%cUPDATE STACK", "color:pink;", "UPDATE");
      newStack = [...prev];
      newStack[index] = { id, render };
      // newStack = [...prev].splice(index, 0, { id, render });
    }
    return newStack;
  };

export const RightMenuProvider = ({ children }) => {
  const [stack, setStack] = useState([]);
  const context = useMemo(
    () => ({
      updateStack: ({ id, render }) => setStack(update({ id, render })),
      stack,
    }),
    [stack]
  );
  // console.log("[RightMenuProvider]", stack);

  return (
    <RightMenuContext.Provider value={context}>
      {children}
    </RightMenuContext.Provider>
  );
};

let counter = 0;

export const useRightMenu = (elementOrFn) => {
  const [id] = useState(() => counter++);
  const { updateStack } = useContext(RightMenuContext);

  const updateStackRef = useRef(updateStack);
  updateStackRef.current = updateStack;

  useEffect(() => {
    const render =
      typeof elementOrFn === "function"
        ? elementOrFn
        : elementOrFn
        ? () => elementOrFn
        : undefined;
    // console.log("%c[useRightMenu.eff]", "color:coral;", { id, element });
    updateStackRef.current({ id, render });
  }, [elementOrFn, id]);

  useEffect(
    () => () => {
      // console.log("%c[useRightMenu.eff cleanup]", "color:coral;", { id });
      updateStackRef.current({ id, render: null });
    },
    [id]
  );
};

export const useRenderRightMenuContent = () => {
  const { stack } = useContext(RightMenuContext);
  return stack[stack.length - 1]?.render;
};
