import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Skeleton,
  Stack,
} from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { ErrorBoundary } from "../../components/ErrorBoundary";
import { useIntersection } from "../../components/Forms/hooks";
import { AvailabilityCalendar } from "../Availability/AvailabilityCalendar";
import { usePickCoach } from "./api";
import { CoachInfo } from "./CoachInfo";
import { ContactModal } from "./ContactModal";
import { IntroLink } from "./IntroLink";
import { formatName, getCoachPhotoUrl } from "./coach.utils";

export const CoachCard = ({
  coach,
  withContact,
  isLoading,
  sx = { mb: 3 },
}) => {
  const { username, webLink } = coach || {};
  const [contactCoach, setContactCoach] = useState(null);
  const [wasVisible, setWasVisible] = useState(false);
  const handleContact = useCallback(() => setContactCoach(coach), [coach]);
  const pickCoach = usePickCoach({ coach });

  const elementRef = useRef();
  useIntersection({
    elementRef,
    onVisibleOnce: () => setWasVisible(true),
  });

  // console.log("[CoachCard.rndr]", name, { isVisible, coach, wasVisible });

  return (
    <>
      <Card sx={{ ...sx }} ref={elementRef}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 3,
            p: 3,
          }}
        >
          <Box
            sx={{
              borderRadius: 0.6,
              minWidth: { xs: 225 },
              width: { xs: 225 },
              maxHeight: 500,
              position: "relative",
              overflow: "hidden",
            }}
          >
            {isLoading ? (
              <Skeleton
                variant="rectangular"
                sx={{ minHeight: 300, width: "100%", height: "100%" }}
              />
            ) : (
              <>
                <CardMedia
                  component="img"
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: 0.6,
                  }}
                  image={wasVisible ? getCoachPhotoUrl(username) : undefined}
                  alt={"profile photo"}
                />
                <IntroLink webLink={webLink} />
              </>
            )}
          </Box>
          {isLoading ? (
            <Stack useFlexGap sx={{ width: "100%" }}>
              <Skeleton
                variant="text"
                sx={{ height: 24, mb: 1, width: "40%" }}
              />
              <Skeleton variant="text" sx={{ width: "90%" }} />
              <Skeleton variant="text" sx={{ width: "70%" }} />
              <Skeleton variant="text" sx={{ width: "90%" }} />
              <Skeleton variant="text" sx={{ width: "30%" }} />
            </Stack>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 3,
                width: "100%",
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "flex-start", md: "flex-end" },
                justifyContent: { xs: "center", md: "flex-end" },
              }}
            >
              <ErrorBoundary>
                <CoachInfo
                  coach={{
                    ...coach,
                    name: formatName(coach),
                  }}
                  maxBioChars={250}
                  isLoading={isLoading}
                  withFavorite
                  sx={{
                    flexGrow: 2,
                    width: { md: "280px" },
                    maxWidth: "100%",
                  }}
                />
              </ErrorBoundary>
              <ErrorBoundary>
                {!isLoading && (
                  <AvailabilityCalendar
                    coach={coach}
                    onContact={withContact && handleContact}
                    onPick={pickCoach.onPick}
                    pickPending={pickCoach.pickPending}
                    fetchDisabled={!wasVisible}
                    sx={{ alignSelf: { xs: "auto", md: "end" } }}
                  />
                )}
              </ErrorBoundary>
            </Box>
          )}
        </CardContent>
      </Card>
      <ContactModal
        coach={contactCoach}
        onClose={() => setContactCoach(null)}
      />
    </>
  );
};
