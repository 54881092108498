import { Box } from "@mui/material";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Layout } from "../../components/Layout";
import { MsgProvider } from "../../components/Msg";
import { useMsg } from "../../components/Msg/Msg";
import { H1, P } from "../../components/Typography";
import { generalMessages } from "../../components/messages";
import { routes } from "../../routes";
import { useAuth } from "../Authorization/AuthProvider";
import { I18nContext } from "../I18n/I18nProvider";
import { CreateFeedbackForm } from "./CreateFeedbackForm";
import { getCollectedMaybe } from "./GetFeedback.page";
import {
  FIELD_DEFAULT_VALUES,
  SHARE_FIELDS,
  ShareFeedbackModal,
} from "./ShareFeedbackModal";
import {
  useCreateFeedbackFormMutation,
  useFeedbackQuery,
  useUpdateFeedbackFormMutation,
} from "./api";
import { DEFAULT_VALUES, FEEDBACK_FIELDS } from "./constants";
import { messages } from "./messages";

const from = ({
  shareFormValues,
  formBuilderValues,
  username,
  language,
  draft = false,
}) => {
  const payload = {
    id: formBuilderValues.id,
    title: formBuilderValues[FEEDBACK_FIELDS.title],
    description: formBuilderValues.description,
    username,
    validTo: shareFormValues[SHARE_FIELDS.validTo],
    questions: formBuilderValues.fields.map(
      ({ title, inputType, required }) => ({
        key: title,
        type: inputType,
        required,
      })
    ),
    recipients: shareFormValues[SHARE_FIELDS.emailList].map(
      ({ id, email, submitted }) => ({
        id,
        submitted: !!submitted,
        username: email,
      })
    ),
    locale: language?.substring(0, 2), // TODO: move to mutation
    draft,
  };
  return payload;
};

const to = (data, { i18n, isCopy }) => {
  return data
    ? {
        ...DEFAULT_VALUES,
        ...data,
        title: isCopy
          ? `${data.title} (copy)`
          : data.title || DEFAULT_VALUES.title,
        [FEEDBACK_FIELDS.fields]: data.questions.map(
          ({ key, type, required }) => ({
            title: key,
            inputType: type,
            required,
          })
        ),
        validTo: data.validTo,
        emailList: data.recipients.length
          ? data.recipients.map(({ username, id, submitted }) => ({
              id,
              submitted,
              email: username,
            }))
          : [FIELD_DEFAULT_VALUES],
      }
    : { ...DEFAULT_VALUES };
};

function CreateFeedbackPageInner({ id, isCopy, isEdit }) {
  const msg = useMsg();
  const generalMsg = useMsg({ dict: generalMessages });
  const navigate = useNavigate();
  const { user } = useAuth();
  const { i18n, language } = useContext(I18nContext);
  const [formBuilderValues, setFormBuilderValues] = useState();

  const query = useFeedbackQuery({
    params: { id },
    enabled: !!id, // refetchQuery issue?
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const initialValues = useMemo(
    () => to(query.data, { i18n, isCopy }),
    [query.data, i18n, isCopy]
  );

  const allowNavigationRef = useRef(false);
  const createMutation = useCreateFeedbackFormMutation({
    onSuccess: () => {
      allowNavigationRef.current = true;
      setFormBuilderValues();
      // if removing navigate, fix allowNavigation
      navigate(routes.getFeedback);
    },
  });
  const updateMutation = useUpdateFeedbackFormMutation({
    params: { id },
    onSuccess: () => {
      allowNavigationRef.current = true;
      setFormBuilderValues();
      // if removing navigate, fix allowNavigation
      navigate(routes.getFeedback);
    },
  });
  const mutation = isEdit ? updateMutation : createMutation;

  console.log("%c[CreateFeedbackPageInner.rndr]", "color:lime", {
    mutation,
    isEdit,
    query,
    initialValues,
  });

  const handleSaveDraft = useCallback(
    (formBuilderValues) => {
      const payload = from({
        shareFormValues: {
          [SHARE_FIELDS.emailList]:
            initialValues?.[SHARE_FIELDS.emailList] || [],
          [SHARE_FIELDS.validTo]: initialValues?.[SHARE_FIELDS.validTo] || null,
        },
        formBuilderValues,
        language,
        username: user.data.username,
        draft: true,
      });
      // debugger;
      mutation.mutate(payload);
    },
    [initialValues, language, mutation, user.data.username]
  );
  const handleSubmit = useCallback(
    (shareFormValues) => {
      const payload = from({
        shareFormValues,
        formBuilderValues,
        language,
        username: user.data.username,
        draft: false,
      });
      // debugger;
      mutation.mutate(payload);
    },
    [formBuilderValues, user.data.username, language, mutation]
  );
  const handleSubmitDraft = useCallback(
    (shareFormValues) => {
      const payload = from({
        shareFormValues,
        formBuilderValues,
        language,
        username: user.data.username,
        draft: true,
      });
      // debugger;
      mutation.mutate(payload);
    },
    [formBuilderValues, user.data.username, language, mutation]
  );
  const isPending = mutation.isPending;
  const isSavingDraft = mutation.isPending && mutation.variables?.draft;
  const isSubmitting = mutation.isPending && !mutation.variables?.draft;

  return (
    <>
      <Box
        sx={{
          mb: 4,
          display: "flex",
          alignItems: "baseline",
        }}
      >
        <H1 sx={{ flex: 1 }}>{msg("feedback.heading")}</H1>
        <P sx={{ ml: 1 }}>{!!isCopy && generalMsg("general.copy")}</P>
        <P sx={{ ml: 1 }}>
          {!!(initialValues?.draft || isEdit)
            ? generalMsg("general.draft")
            : ""}
        </P>
        {/* TODO: initialValues?.draft always false, BE - @jk */}
      </Box>
      <CreateFeedbackForm
        editFormQuery={query}
        values={initialValues}
        onShareForm={setFormBuilderValues}
        onSaveDraft={handleSaveDraft}
        collected={!isCopy && !isEdit && getCollectedMaybe(query.data)}
        isEdit={isEdit}
        isCopy={isCopy}
        isPending={isPending}
        isSavingDraft={isSavingDraft}
        allowNavigationRef={allowNavigationRef}
      />
      {!!formBuilderValues && ( // TODO: Modal with form reinit
        <ShareFeedbackModal
          open={!!formBuilderValues}
          onSubmit={handleSubmit}
          onSaveDraft={handleSubmitDraft}
          onClose={() => setFormBuilderValues()}
          //   link="http://topleader.io/juRcHHx7r8QTPYP"
          link=""
          isPending={isPending}
          isSubmitting={isSubmitting}
          isSavingDraft={isSavingDraft}
          initialValues={initialValues}
        />
      )}
    </>
  );
}

export function CreateFeedbackPage({ isCopy, isEdit }) {
  const { id } = useParams();
  const generalMsg = useMsg({ dict: generalMessages });

  console.log("[CreateFeedbackPage.rndr]", { id });

  return (
    <MsgProvider messages={messages}>
      <Layout
        header={{
          back: { href: routes.getFeedback, text: generalMsg("general.back") },
        }}
      >
        <CreateFeedbackPageInner id={id} isCopy={isCopy} isEdit={isEdit} />
      </Layout>
    </MsgProvider>
  );
}
