import { LoadingButton } from "@mui/lab";
import { Alert, Box, Tooltip } from "@mui/material";
import { addDays, addHours, addMinutes } from "date-fns";
import { always } from "ramda";
import { useContext } from "react";
import { useMsg } from "../../../components/Msg/Msg";
import { useMyMutation } from "../../Authorization/AuthProvider";
import { I18nContext } from "../../I18n/I18nProvider";
import { useFeedbackNotificationsQuery } from "../api";
import { messages } from "../messages";
import { IconWithProgress } from "./IconWithProgress";

const ManualReminderButton = ({ feedbackId }) => {
  const msg = useMsg({ dict: messages });
  // const [dialogOpen, setDialogOpen] = useState(false);
  // const user = useAuth().userQuery.data;
  // const senderName = formatName(user);
  const mutation = useMyMutation({
    fetchDef: {
      method: "POST",
      url: `/api/latest/feedback-notification/${feedbackId}/manual-reminder`,
      from: always({}),
    },
    invalidate: [{ queryKey: ["feedback", "notification", feedbackId] }],
  });

  return (
    <>
      <LoadingButton
        loading={mutation.isPending}
        color="inherit"
        onClick={() => mutation.mutate()}
      >
        {msg("dict.feedback.results.status.canRemindManually.send")}
      </LoadingButton>
      {/* Custom email text postponed/removed @Dan */}
      {/* <ManualReminderDialog
        feedbackId={undefined} // TODO: params
        senderName={senderName}
        deadlineDate={deadlineDate}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      /> */}
    </>
  );
};

const STATUS = {
  initial: "initial",
  reminded: "reminded",
  canRemindManually: "canRemindManually",
  manuallyReminded: "manuallyReminded",
};

const getPercentPassed = (firstDate, secondDate, nowDate) => {
  try {
    if (!firstDate || !secondDate || !nowDate) return undefined;
    const totalMs = secondDate - firstDate;
    const passedMs = nowDate - firstDate;
    const percentPassed = Math.round(passedMs / (totalMs / 100));
    console.log(">>>", {
      firstDate,
      secondDate,
      nowDate,
      percentPassed,
      totalMs,
      passedMs,
    });

    if (nowDate < firstDate) return 0;
    if (nowDate > secondDate) return 100;
    return percentPassed;
  } catch (e) {
    console.error("[ReminderStatus.getPercentPassed]", e);
    return undefined;
  }
};

const formatDaysCount = ({ start, end }) => {
  const hoursFloat = (end - start) / (1000 * 60 * 60);
  const days = Math.max(0, Math.round(hoursFloat / 24));
  const hours = Math.max(0, Math.round(hoursFloat % 24));

  return { days, hours };
};

const usePropsByStatus = ({
  status,
  sendDate,
  reminderDate,
  manualReminderAllowedAfter,
  manualReminderSentTimeMaybe,
  feedbackId,
}) => {
  const { i18n } = useContext(I18nContext);
  const msg = useMsg({ dict: messages });
  const DATE_FORMAT = "Pp";

  switch (status) {
    case STATUS.initial: {
      const reminderPercent = getPercentPassed(
        sendDate,
        reminderDate,
        new Date()
      );

      return {
        text: msg.maybe("dict.feedback.results.status.initial.text", {
          reminderDate: i18n.formatLocal(reminderDate, DATE_FORMAT),
        }),
        tooltip: msg.maybe("dict.feedback.results.status.initial.tooltip", {
          reminderSentAfterDays: 5,
        }),
        iconName: "Mail",
        valuePercent: reminderPercent,
      };
    }
    case STATUS.reminded: {
      const { days: manualInDays, hours: manualInHours } = formatDaysCount({
        start: new Date(),
        end: manualReminderAllowedAfter,
      });
      const manualPercent = getPercentPassed(
        reminderDate,
        manualReminderAllowedAfter,
        new Date()
      );

      return {
        text: msg.maybe("dict.feedback.results.status.reminded.text", {
          reminderSentDate: i18n.formatLocal(reminderDate, DATE_FORMAT),
        }),
        tooltip:
          !!manualReminderAllowedAfter &&
          msg.maybe("dict.feedback.results.status.reminded.tooltip", {
            manualInDays,
            manualInHours,
          }),
        iconName: "NotificationImportant",
        valuePercent: manualPercent,
      };
    }
    case STATUS.canRemindManually: {
      return {
        text: msg.maybe("dict.feedback.results.status.canRemindManually.text", {
          reminderSentDate: i18n.formatLocal(reminderDate, DATE_FORMAT),
        }),
        tooltip: "",
        iconName: "Campaign",
        ActionComponent: ManualReminderButton,
        actionProps: { feedbackId },
      };
    }
    case STATUS.manuallyReminded: {
      return {
        text: msg.maybe("dict.feedback.results.status.manuallyReminded.text", {
          hasDate: manualReminderSentTimeMaybe ? "true" : "false",
          manualReminderDate: manualReminderSentTimeMaybe
            ? i18n.formatLocal(manualReminderSentTimeMaybe, DATE_FORMAT)
            : "",
        }),
        tooltip: "",
        iconName: "Check",
      };
    }
    default:
      return {};
  }
};

const ReminderStatusInner = ({
  status = STATUS.initial,
  sendDate,
  reminderDate,
  manualReminderAllowedAfter,
  manualReminderSentTimeMaybe,
  feedbackId,
  color = "primary",
  // color = "info",
}) => {
  const {
    text,
    tooltip,
    iconName,
    valuePercent,
    ActionComponent,
    actionProps = {},
  } = usePropsByStatus({
    feedbackId,
    status,
    sendDate,
    reminderDate,
    manualReminderAllowedAfter,
    manualReminderSentTimeMaybe,
  });

  if (!text) return null;

  return (
    <Tooltip title={tooltip}>
      <Alert
        severity={color}
        variant="standard"
        color={color}
        action={ActionComponent && <ActionComponent {...actionProps} />}
        icon={
          <IconWithProgress
            hideProgress={typeof valuePercent !== "number"}
            value={valuePercent}
            iconName={iconName}
            size={30}
            iconSize={"small"}
            thickness={3}
            color={color}
          />
        }
      >
        {text}
      </Alert>
    </Tooltip>
  );
};

export const ReminderStatus = ({ feedbackId }) => {
  const query = useFeedbackNotificationsQuery({ feedbackId });

  if (!query.data) return null;

  const {
    automaticReminderTime,
    automaticReminderSentTime,
    feedbackFormEmailTime,
    manualReminderAllowedAfter,
    feedbackFormId,
    manualReminderAllowed,
    status: notifStatus,
    manualReminderSentTime, // BE missing
    // deadlineDate,
  } = query.data;
  const reminderDate = automaticReminderSentTime || automaticReminderTime;
  const status =
    notifStatus === "MANUAL_SENT"
      ? STATUS.manuallyReminded
      : manualReminderAllowed
      ? STATUS.canRemindManually
      : automaticReminderSentTime
      ? STATUS.reminded
      : STATUS.initial;

  return (
    <ReminderStatusInner
      feedbackId={feedbackId}
      status={status}
      sendDate={feedbackFormEmailTime}
      reminderDate={reminderDate}
      manualReminderAllowedAfter={manualReminderAllowedAfter}
      manualReminderSentTimeMaybe={manualReminderSentTime}
    />
  );
};

export const ReminderStatusExamples = ({ color = "info" }) => {
  const now = new Date();
  const sendDate = addDays(now, -2);
  const reminderDate = addDays(sendDate, 5);
  const manualReminderAllowedAfter = addDays(reminderDate, 3);

  return (
    <>
      <Box sx={{ my: 3 }} />
      All statuses examples (color={color})
      <Box sx={{ my: 1 }} />
      <ReminderStatusInner
        color={color}
        status={STATUS.initial}
        sendDate={sendDate}
        reminderDate={reminderDate}
      />
      <Box sx={{ pb: 3 }} />
      <ReminderStatusInner
        color={color}
        status={STATUS.reminded}
        reminderDate={addHours(new Date(), -10)}
        manualReminderAllowedAfter={manualReminderAllowedAfter}
      />
      <Box sx={{ pb: 1 }} />
      <ReminderStatusInner
        color={color}
        status={STATUS.reminded}
        reminderDate={addHours(new Date(), -10)}
        manualReminderAllowedAfter={addMinutes(new Date(), 15)}
      />
      <Box sx={{ pb: 1 }} />
      <ReminderStatusInner
        color={color}
        status={STATUS.reminded}
        reminderDate={addHours(new Date(), -10)}
        manualReminderAllowedAfter={addHours(new Date(), 1)}
      />
      <Box sx={{ pb: 1 }} />
      <ReminderStatusInner
        color={color}
        status={STATUS.reminded}
        reminderDate={addHours(new Date(), -10)}
        manualReminderAllowedAfter={addHours(new Date(), 25)}
      />
      <Box sx={{ pb: 3 }} />
      <ReminderStatusInner
        color={color}
        status={STATUS.canRemindManually}
        reminderDate={reminderDate}
      />
      <Box sx={{ pb: 3 }} />
      <ReminderStatusInner color={color} status={STATUS.manuallyReminded} />
      <Box sx={{ pb: 1 }} />
      <ReminderStatusInner
        color={color}
        status={STATUS.manuallyReminded}
        manualReminderSentTimeMaybe={addHours(new Date(), -10)}
      />
      <Box sx={{ pb: 3 }} />
      <Box sx={{ pb: 3 }} />
      <Box sx={{ pb: 3 }} />
      <Box sx={{ pb: 3 }} />
      <Box sx={{ pb: 3 }} />
      <Box sx={{ pb: 3 }} />
    </>
  );
};
