import { Box, Button } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { UserAvatar } from "../../components/Avatar/UserAvatar";
import { RHFTextField } from "../../components/Forms";
import { RHForm } from "../../components/Forms/Form";
import { useMsg } from "../../components/Msg/Msg";
import { H2 } from "../../components/Typography";
import { Loaders, QueryRenderer } from "../QM/QueryRenderer";
import { ConversationMessage } from "./ConversationMessage";
import { useConversationMessagesQuery, useSendMessageMutation } from "./api";

export const Conversation = ({
  addressee,
  name,
  restHeight: restHeightProp = 0,
}) => {
  const msg = useMsg();
  // const name = `${coach.firstName} ${coach.lastName}`;
  const methods = useForm({
    mode: "onSubmit",
    defaultValues: { message: "" },
  });
  const headerHeight = 92;
  const footerHeight = 113;
  const restHeight = restHeightProp + headerHeight + footerHeight;

  const messagesQuery = useConversationMessagesQuery({ addressee });
  const queryClient = useQueryClient();
  const sendMutation = useSendMessageMutation({
    onMutate: async (newMessage) => {
      const { userTo, messageData } = newMessage;
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ["messages", addressee] });

      // Snapshot the previous value
      const previousMessages = queryClient.getQueryData([
        "messages",
        addressee,
      ]);

      // Optimistically update to the new value
      queryClient.setQueryData(["messages", addressee], (old) => [
        ...old,
        {
          id: Math.random(),
          fromMe: true,
          text: messageData,
          isOptimisticUpdate: true,
          createdAt: new Date(),
        },
      ]);

      // Return a context object with the snapshotted value
      return { previousMessages };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newMessage, context) => {
      queryClient.setQueryData(
        ["messages", addressee],
        context.previousMessages
      );
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["messages", addressee] });
    },
  });

  const handleSubmit = useCallback(
    async ({ message }) => {
      console.log("handleSubmit", { message });
      // await sendMutation.mutateAsync({
      sendMutation.mutateAsync({
        userTo: addressee,
        messageData: message,
      });
      methods.reset();
    },
    [addressee, methods, sendMutation]
  );

  useEffect(() => console.log("queryClient ref changed"), [queryClient]);
  const onLastMessageChange = useCallback(
    async ({ addressee, message }) => {
      // Set last message in conversations list:
      await queryClient.cancelQueries({
        queryKey: ["messages"],
        exact: true,
      });
      queryClient.setQueryData(["messages"], (old, ...rest) => {
        if (!old) console.error("TODO: investigate", { old, rest });
        const index = old.findIndex((c) => c.username === addressee);
        const updateCandidateMaybe = index >= 0 ? old[index] : undefined;
        if (
          !updateCandidateMaybe ||
          updateCandidateMaybe.lastMessage === message
        )
          return old;

        const copy = [...old];
        copy[index] = { ...copy[index], lastMessage: message };

        return copy;
      });
    },
    [queryClient]
  );
  const lastMessage =
    messagesQuery.data?.[messagesQuery.data?.length - 1]?.text;
  useEffect(() => {
    if (lastMessage) {
      const payload = { addressee, message: lastMessage };
      console.log("eff.onLastMessageChange", payload);
      onLastMessageChange?.(payload);
    }
  }, [addressee, lastMessage, onLastMessageChange]);

  console.log("[Conversation.rndr]", {
    addressee,
    messagesQuery,
    sendMutation,
  });

  if (!addressee) return null;

  return (
    <Box
      sx={{
        display: "flex",
        flexFlow: "column nowrap",
        width: "100%",
        mr: -4,
      }}
    >
      <Box
        sx={{
          p: 3,
          height: `${headerHeight}px`,
          borderBottom: "1px solid #EAECF0",
          bgcolor: "background.paper",
          display: "flex",
          alignItems: "center",
        }}
      >
        <UserAvatar username={addressee} fullName={name} sx={{ mr: 1.5 }} />
        <H2 title={!name ? undefined : addressee}>{name || addressee}</H2>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          overflow: "auto",
          p: 3,
          height: `calc(100% - ${restHeight}px)`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "flex-end",
            minHeight: "100%",
            gap: 3,
          }}
        >
          <QueryRenderer
            loaderEl={<Loaders.CircularBlock fullHeight />}
            query={messagesQuery}
            success={({ data }) =>
              data.map((message, index) => (
                <ConversationMessage
                  key={message.id}
                  message={message}
                  scrollIntoView={index + 1 === messagesQuery.data?.length}
                />
              ))
            }
          />
        </Box>
      </Box>
      <RHForm
        sx={{
          p: 3,
          pb: 4,
          bgcolor: "background.paper",
          display: "flex",
          alignItems: "center",
          borderTop: "1px solid #EAECF0",
          height: footerHeight,
        }}
        form={methods}
        onSubmit={handleSubmit}
      >
        <Box sx={{ flex: "1 1 100%" }}>
          <RHFTextField
            name="message"
            rules={{ required: true }}
            label={""}
            placeholder={msg("messages.conversation.message.placeholder")}
            size="small"
            hiddenLabel
            multiline
            rows={2}
            sx={{ pr: 3 }}
            fullWidth
            autoFocus
            // {...register("message", { required: true })}
          />
        </Box>
        <Button variant="contained" type="submit">
          {msg("messages.conversation.send")}
        </Button>
      </RHForm>
    </Box>
  );
};
